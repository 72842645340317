import { useState, useEffect } from "react";

const useTypingEffect = (text: string, speed: number, startTyping:boolean) => {
   const [displayedText, setDisplayedText] = useState('');
   
     
   useEffect(() => {
      console.log(startTyping)
      if(!startTyping) return setDisplayedText('')
         
     let index = 0;
     const interval = setInterval(() => {
       setDisplayedText((prev: string) => prev + text[index]);
       index++;
       if (index === text.length) {
         clearInterval(interval);
       }
     }, speed);
     
     return () => clearInterval(interval);
   }, [text, speed, startTyping]);
   
   return displayedText;
 };

export default useTypingEffect