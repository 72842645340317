import { makeStyles } from "tss-react/mui";
import { ChangeEvent, FC } from "react";
import { Theme } from "@mui/material";
import { useField } from "formik";

interface FieldInputProps {
  onChange?: (e: string | number | ChangeEvent<any>) => void;
  placeholder: string;
  type: string;
  name: string;
  required?: boolean;
  onBlur?: () => void;
  disabled?: boolean;
  defaultValue?: string;
  value?: string;
  addClassname?: string;
}


const FieldInput:FC<FieldInputProps> = ({
   type,
   name,
   onChange,
   required,
   disabled,
   defaultValue,
   placeholder,
   addClassname,
   value,
 }) => {
   const { classes } = useStyles();
   const [field, meta] = useField(name);
   return (
      <input 
        type={type}
        name={name}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        onChange={field.onChange} 
        defaultValue={defaultValue}
        value={field.value} 
        className={`${addClassname} ${classes.input}`}
      />
   );
};

const useStyles = makeStyles({ "name": { FieldInput } })((theme: Theme) => ({
   input: {
      width: '100%',
      borderRadius: '10px',
      border: "1px solid #B4B4B4",
      background: '#FFF',
      color: '#000',
      fontSize: '15px',
      padding: '15px 0px 15px 15px',

     
      "&::placeholder": {
        
         color: '#B4B4B4',
      },
      
      [`@media (min-width: ${theme.breakpoints.values.lg -1}px)`]:{
         fontSize: '16px',
         
      }
   }
}));

export default FieldInput;
