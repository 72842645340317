import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme } from "@mui/material";
import MainSection from "./MainSection/MainSection";
import BenefitsSection from "./BenefitsSection/BenefitsSection";
import CoursesSection from "./CoursesSection/CoursesSection";
import PriceSection from "./PriceSection/PriceSection";
import TeamSection from "./TeamSection/TeamSection";
import FeedbackSection from "./FeedbackSection/FeedbackSection";
import FormSection from "./FormSection/FormSection";
import FAQSection from "./FAQSection/FAQSection";
import BlogSection from "./BlogSection/BlogSection";
import StudentsLocation from "./StudentsLocation/StudentsLocation";
import { Helmet } from "react-helmet-async";

interface HomeProps {}

const Home: FC<HomeProps> = ({}) => {
  const { classes } = useStyles();
  return (
    <main className={`${classes.wrapper} main-container`}>
      <Helmet>
        <title>{"EnglishLoom"}</title>
      </Helmet>
     
      <MainSection />
      <BenefitsSection />
      <StudentsLocation />
      <CoursesSection />
      <PriceSection /> 
      {/* <TeamSection /> */}
      <FeedbackSection />
      <FormSection />
      <FAQSection />
      <BlogSection />
    </main>
  );
};

const useStyles = makeStyles({ name: { Home } })((theme: Theme) => ({
  wrapper: {
    position: "relative",
    marginTop: "30px",

    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginTop: "65px",
    },

    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      marginTop: "100px",
    },
  },
}));

export default Home;
