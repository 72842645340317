import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme } from "@mui/material";
import { ReactSVG } from 'react-svg'
import SectionTitle from "../../../shared/components/SectionTitle/SectionTitle";
import UseMediaQuery from "../../../hooks/UseMediaQuery";
import MobileBlock from './MobileBlock/MobileBlock';
import DesktopBlock from './DesktopBlock/DesktopBlock';
import SlideArrows from "../../../shared/components/SlideArrows/SlideArrows";
import flowerIcon from '../../../assets/icons/common/flower-vector.svg'
import rainIcon from '../../../assets/icons/union/rain.svg'
import { useTranslation } from "react-i18next";

interface FeedbackSectionProps {}

const FeedbackSection:FC<FeedbackSectionProps> = ({}) => {
   const { classes } = useStyles();
   const { isDesktop } = UseMediaQuery()
   const { t } = useTranslation()
   const feedbacks = [
      {id: 1, name: t('scenes.home.feedback-section.first-item.name'), text: t('scenes.home.feedback-section.first-item.text')},
      {id: 2, name: t('scenes.home.feedback-section.second-item.name'), text: t('scenes.home.feedback-section.second-item.text')},
      {id: 3, name: t('scenes.home.feedback-section.third-item.name'), text: t('scenes.home.feedback-section.third-item.text')},
      {id: 4, name: t('scenes.home.feedback-section.fourth-item.name'), text: t('scenes.home.feedback-section.fourth-item.text')},
      {id: 5, name: t('scenes.home.feedback-section.fifth-item.name'), text: t('scenes.home.feedback-section.fifth-item.text')},
      {id: 6, name: t('scenes.home.feedback-section.sixth-item.name'), text: t('scenes.home.feedback-section.sixth-item.text')},
      {id: 7, name: t('scenes.home.feedback-section.seventh-item.name'), text: t('scenes.home.feedback-section.seventh-item.text')},
      {id: 8, name: t('scenes.home.feedback-section.eigth-item.name'), text: t('scenes.home.feedback-section.eigth-item.text')},
      {id: 9, name: t('scenes.home.feedback-section.ninth-item.name'), text: t('scenes.home.feedback-section.ninth-item.text')},
      {id: 10, name: t('scenes.home.feedback-section.tenth-item.name'), text: t('scenes.home.feedback-section.tenth-item.text')},
      {id: 11, name: t('scenes.home.feedback-section.eleventh-item.name'), text: t('scenes.home.feedback-section.eleventh-item.text')}
   ]
   return (
      <section className={classes.wrapper}>
         <ReactSVG src={flowerIcon} className={classes.flowerIcon}/>
         <ReactSVG src={rainIcon} className={classes.rainIcon}/>
         <div className={classes.topWrapper}>
            <SectionTitle title={t('scenes.home.feedback-section.title')} />
           {isDesktop && <SlideArrows  prevClassName="feedbacks-prev" nextClassName="feedbacks-next"/>}
         </div>
        
      

         {!isDesktop && <MobileBlock feedbacks={feedbacks}/>}
         {isDesktop && <DesktopBlock feedbacks={feedbacks} />}

      </section>
   );
};

const useStyles = makeStyles({ "name": { FeedbackSection } })((theme: Theme) => ({
   wrapper: {  
      position: "relative",
      marginBottom: '70px',
      [`@media (max-width: ${theme.breakpoints.values.sm -1}px)`]:{        
         marginBottom: '100px',
      },
      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]:{
         marginBottom: '160px',
      }

   },
   flowerIcon: {
      position: 'absolute',
      left: '-20px',
      top: '35px',
      width:"54px",
      height:"75px",

      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]: {
         width:"90px",
         height:"175px",
         left: '-85px',
      }
   },
   rainIcon: {
      position: 'absolute',
      left: '-50px',
      top: '40px',
      width: '70px',
      stroke: theme.palette.primary.main,
      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]: {
         width:"120px",
         height:"175px",
         left: '-130px',
      }
   },
   topWrapper: {
      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]: {
         display: 'flex',
         justifyContent: 'space-between',
      }
      

   }
}));

export default FeedbackSection;
