import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface LanguagesChangerProps {}

const LanguagesChanger: FC<LanguagesChangerProps> = () => {
  const { classes } = useStyles();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const languageChanger = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
  };

  return (
    <div className={classes.languageSwitcherWrapper}>
      <Typography
        variant="h5"
        className={`${classes.lang} ${
          currentLanguage === "ua" ? classes.active : ""
        }`}
        onClick={() => languageChanger("ua")}
      >
        УКР
      </Typography>
      <div className={classes.langBorder}></div>
      <Typography
        variant="h5"
        className={`${classes.lang} ${
          currentLanguage === "en" ? classes.active : ""
        }`}
        onClick={() => languageChanger("en")}
      >
        EN
      </Typography>
      {/* <div className={classes.langBorder}></div> */}
      {/* <Typography
        variant="h5"
        className={`${classes.lang} ${
          currentLanguage === "fr" ? classes.active : ""
        }`}
        onClick={() => languageChanger("fr")}
      >
        FR
      </Typography> */}
    </div>
  );
};

const useStyles = makeStyles({ name: { LanguagesChanger } })(
  (theme: Theme) => ({
    languageSwitcherWrapper: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },

    lang: {
      color: "#3F3E3E",
      fontWeight: "600",

      [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
        fontSize: "20px",
      },
    },
    active: {
      color: "#FFF",
    },
    langBorder: {
      width: "2px",
      height: "20px",
      borderRadius: "1px",
      background: "#3F3E3E",
      margin: "0px 10px",
    },
  })
);

export default LanguagesChanger;
