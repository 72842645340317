import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface NavigationTabProps {
  currentPage: string;
  prevPage?: string;
}

const NavigationTab: FC<NavigationTabProps> = ({ currentPage, prevPage }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const routes = [
    { name: t("components.nav-list.home"), route: "/" },
    { name: t("components.nav-list.contacts"), route: "/contacts" },
    { name: t("components.nav-list.blog"), route: "/blog" },
  ];
  return (
    <ul className={classes.list}>
      <li className={classes.item}>
        <Link to={"/"}>
          <Typography variant={"h5"} className={classes.fromPage}>
            {t("components.nav-list.home")}
          </Typography>
        </Link>

        <span className={classes.nextEL}> {">"} </span>
      </li>
      {prevPage && (
        <li className={classes.item}>
          {routes.map(({ route, name }) =>
            route === prevPage ? (
              <Link to={route}>
                <Typography
                  variant={"h5"}
                  className={classes.fromPage}
                  key={name}
                >
                  {name}
                </Typography>
              </Link>
            ) : null
          )}

          <span className={classes.nextEL}> {">"} </span>
        </li>
      )}
      <li className={classes.item}>
        <Typography variant={"h5"} className={classes.currentPage}>
          {currentPage}
        </Typography>
      </li>
    </ul>
  );
};

const useStyles = makeStyles({ name: { NavigationTab } })((theme: Theme) => ({
  list: {
    margin: "40px 0px",
    display: "flex",
    userSelect: 'none'
  },
  item: {
    display: "flex",
    alignItems: "center",
    "&:not(:last-child)": {
      marginRight: "10px",
    },
  },
  fromPage: {
    marginRight: "10px",
    color: "#B4B4B4",
    transition: 'all 0.3s ease-in-out',
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  nextEL: {
    color: "#B4B4B4",
  },
  currentPage: {},
}));

export default NavigationTab;
