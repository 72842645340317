import { makeStyles } from "tss-react/mui";
import { FC, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Theme, Typography } from "@mui/material";
import SectionTitle from "../../../shared/components/SectionTitle/SectionTitle";
import mobileMap from "../../../assets/icons/maps/mobile-map.svg";
import desktopMap from "../../../assets/icons/maps/desktop-map.svg";
import { ReactSVG } from "react-svg";
import UseMediaQuery from "../../../hooks/UseMediaQuery";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../shared/components/CommonButton/CommonButton";
import AnchorLink from "react-anchor-link-smooth-scroll";

interface StudentsLocationProps {}

const StudentsLocation: FC<StudentsLocationProps> = ({}) => {
  const { classes } = useStyles();
  const { isDesktop } = UseMediaQuery();
  const { t } = useTranslation();

  return (
    <section className={classes.wrapper}>
      <div className={classes.textWrapper}>
        <SectionTitle title={t('scenes.home.students-location-section.title')} />
        <Typography variant="h5" className={classes.text}>
          {t('scenes.home.students-location-section.text')}
        </Typography>
        {isDesktop && (
          <AnchorLink offset="100" href="#form-section">
            <CommonButton text={t("common-texts.free-lesson")} />
          </AnchorLink>
        )}
      </div>
      <div className={classes.map}>
        <ReactSVG src={!isDesktop ? mobileMap : desktopMap} />
      </div>

      {!isDesktop && (
        <AnchorLink offset="100" href="#form-section">
          <CommonButton text={t("common-texts.free-lesson")} />
        </AnchorLink>
      )}
    </section>
  );
};

const useStyles = makeStyles({ name: { StudentsLocation } })(
  (theme: Theme) => ({
    wrapper: {
      marginBottom: "75px",

      [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
        position: "relative",
        marginBottom: "310px",
      },
    },
    textWrapper: {},
    text: {
      [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
        maxWidth: "410px",
        marginBottom: "45px",
      },
      [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
        maxWidth: "510px",
        marginBottom: "45px",
      },
    },
    map: {
      [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
        position: "absolute",
        right: "-10%",
        top: "-15%",
      },
      [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
        position: "absolute",
        right: "-5%",
        top: "-15%",
      },
    },
  })
);

export default StudentsLocation;
