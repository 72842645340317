import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme } from "@mui/material";
import arrow from "../../../assets/icons/arrow/arrow-block-right.svg";
import { ReactSVG } from "react-svg";
import NavigateBtn from "../NavigateBtn/NavigateBtn";
import { Link } from "react-router-dom";
interface SlideArrowsProps {
  prevClassName: string;
  nextClassName: string;
  showNavigateBtn?: boolean;
  handlePrevClick?: any;
  handleNavClick?: any;
  handleNextClick?: any;
  navigatePathName?: string;
  wrapperClassName?: string;
}

const SlideArrows: FC<SlideArrowsProps> = ({
  prevClassName,
  nextClassName,
  showNavigateBtn,
  handlePrevClick,
  handleNextClick,
  navigatePathName,
  wrapperClassName
}) => {
  const { classes } = useStyles();
  return (
    <div className={`${classes.wrapper} ${wrapperClassName ? wrapperClassName : ''}`}>
      <ReactSVG
        src={arrow}
        className={` ${prevClassName} ${classes.arrow} ${classes.prev}`}
        onClick={() => {
          if (handlePrevClick) {
            handlePrevClick();
          }
        }}
      />
      {showNavigateBtn && (
        <Link
          to={navigatePathName}
          onClick={() => {
            window.scroll(0, 0);
          }}
        >
          <NavigateBtn />
        </Link>
      )}
      <ReactSVG
        src={arrow}
        className={` ${nextClassName} ${classes.arrow} ${classes.next}`}
        onClick={() => {
          if (handleNextClick) {
            console.log('next')
            handleNextClick();
          }
        }}
      />
    </div>
  );
};

const useStyles = makeStyles({ name: { SlideArrows } })((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    gap: "30px",

    "& .swiper-button-disabled": {
      opacity: "0.5",
    },
    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      justifyContent: 'center',
      marginTop: '10px',

    },
  },
  arrow: {
    cursor: "pointer",
    transition: 'all 0.3s ease-in-out',
    fill: "white",
    width: "38px",
    height: "38px",

    "&:hover": {
      opacity: "0.9",
      fill: "grey",
    },
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]:{
      width: "58px",
      height: "58px",
    }
  },
  prev: {
    transform: "scale(-1, 1)",
  },
  next: {},
}));

export default SlideArrows;
