import { makeStyles } from "tss-react/mui";
import { FC, useState } from "react";
import { Theme, Typography } from "@mui/material";
import NavigationTab from "../../components/NavigationTab/NavigationTab";
import SectionTitle from "../../shared/components/SectionTitle/SectionTitle";
import { useBlogArticles } from "../../helpers/blogArcticles";
import PopularArticalList from "../../components/PopularArticalList/PopularArticalList";
import UseMediaQuery from "../../hooks/UseMediaQuery";
import ContactUsBlock from "../../components/ContactUsBlock/ContactUsBlock";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import MoreBtn from "../../shared/components/MoreBtn/MoreBtn";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
interface BlogProps {}

const Blog: FC<BlogProps> = ({}) => {
  const { classes } = useStyles();
  const { articles } = useBlogArticles();
  const { isDesktop } = UseMediaQuery();
  const location = useLocation();
  const { t } = useTranslation()
  const [isMore, setIsMore] = useState(false);
  return (
    <motion.main 
      className={`${classes.wrapper} main-container`}
      initial={{ opacity: 0}}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0}}
      transition={{ duration: 0.5 }}
    >
      <Helmet>
        <title>{t("components.nav-list.blog")}</title>
        <meta name="description" content={'Вивчай англійську разом з EnglishLoom'} />
      </Helmet>
      <NavigationTab currentPage={t("components.nav-list.blog")} />
      <section className={classes.sectionWrapper}>
        <div className={classes.popularWrapper}>
          <h1 style={{display: 'none'}}>{t("scenes.blog.popular-title")}</h1>
          <SectionTitle title={t("scenes.blog.popular-title")} />
          <PopularArticalList />
        </div>
        <div>
          <SectionTitle title={t("scenes.blog.all-title")} />
          <ul className={classes.list}>
            {articles.allArticles.map(({ id, title, text, image }, index) => (
              <li key={id} className={`${classes.item}`} style={{display: isMore || index <= 2 ? '' : 'none'}}>
                <Link
                  to={`/blog/${id}`}
                  className={classes.itemLink}
                  state={{ from: location.pathname }}
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <img src={image} alt="article" className={classes.image} />
                  <div className={classes.textWrapper}>
                    <Typography variant="h5" className={classes.title}>
                      {title}
                    </Typography>
                    <Typography variant="h5" className={classes.text}>
                      {text.substring(0, isDesktop ? 168 : 68)}...
                    </Typography>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
          <div className={classes.moreBtnWrapper}>
            <MoreBtn handleClick={() => setIsMore(prev => !prev)}  text={isMore ? t('common-texts.less-btn') : ""}/>
          </div>
          
        </div>
      </section>

      <ContactUsBlock />
    </motion.main>
  );
};

const useStyles = makeStyles({ name: { Blog } })((theme: Theme) => ({
  wrapper: {
    marginBottom: "70px",
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: "100px",
    },
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: "180px",
    },
  },
  sectionWrapper: {
    marginBottom: "70px",
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: "100px",
    },
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: "150px",
    },
  },
  popularWrapper: {
    paddingBottom: "40px",
    borderBottom: "1px solid #B4B4B4",
    marginBottom: "35px",
  },
  list: {
      marginBottom: '30px',
    [`@media (min-width: ${
      theme.breakpoints.values.sm - 1
    }px) and (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
      display: "flex",
      flexWrap: "wrap",
      gap: "20px",
    },
  },
  item: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "15px",
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      boxShadow: `0px 0px 5px 0px ${theme.palette.primary.main}`,
    },
    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      "&:not(:last-child)": {
        marginBottom: "30px",
      },
    },
    [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
      flexDirection: "column",
    },
    [`@media (min-width: ${
      theme.breakpoints.values.sm - 1
    }px) and (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
      flexBasis: "calc(100% / 2 - 10px)",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      "&:not(:last-child)": {
        marginBottom: "40px",
      },
    },
  },
  itemLink: {
      display: "flex",
      alignItems: "center",
      [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
         flexDirection: "column",
      },
  },
  image: {
    display: "block",
    width: "100%",
    marginBottom: "15px",
    borderRadius: "15px",
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      width: "365px",
      height: "200px",
      marginBottom: "0px",
      marginRight: "25px",
    },
  },
  textWrapper: {},
  title: {
    maxWidth: "624px",
    fontWeight: 700,
    marginBottom: "10px",
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      marginBottom: "32px",
    },
  },
  text: {
    maxWidth: "624px",
  },
  moreBtnWrapper: {
   display: 'flex',
   justifyContent: "center"
  },
}));

export default Blog;
