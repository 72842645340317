import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme } from "@mui/material";
import ContactUsBlock from "../../components/ContactUsBlock/ContactUsBlock";
import NavigationTab from "../../components/NavigationTab/NavigationTab";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
interface LessonFormProps {}

const LessonForm:FC<LessonFormProps> = ({}) => {
   const { classes } = useStyles();
   const { t } = useTranslation();
   return (
      <main className={`${classes.wrapper} main-container`}>
         <Helmet>
            <title>{t('components.nav-list.free-lesson')}</title>
            <meta name="description" content={"Починайте навчання вже сьогодні! Записуйтесь на безкоштовний пробний урок!"} />
         </Helmet>
         <NavigationTab currentPage={t('components.nav-list.free-lesson')} />
         <ContactUsBlock />
      </main>
   );
};

const useStyles = makeStyles({ "name": { LessonForm } })((theme: Theme) => ({
   wrapper: {
      marginBottom: "70px",
      [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
        marginBottom: "100px",
      },
   }
}));

export default LessonForm;
