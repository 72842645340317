import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import peopleIcon from "../../assets/icons/common/people.svg";
import { ReactSVG } from "react-svg";
import MoreBtn from "../../shared/components/MoreBtn/MoreBtn";
import UseMediaQuery from "../../hooks/UseMediaQuery";
import { useTranslation } from "react-i18next";
interface FeedbackItemProps {
  id: number;
  name: string;
  text: string;
  isExpanded?: boolean;
  toggleExpanded?: any;
}

const FeedbackItem: FC<FeedbackItemProps> = ({
  id,
  name,
  text,
  isExpanded,
  toggleExpanded,
}) => {
  const largeText = text.length >= 368;
  const { classes } = useStyles({ showMore: isExpanded, largeText: largeText });
  const { isDesktop } = UseMediaQuery();
  const { t } = useTranslation()
  return (
   
    <li
      className={classes.item}
     
    >
      <div className={classes.nameWrapper}>
        <ReactSVG src={peopleIcon} className={classes.peopleIcon} />
        <Typography variant="h5" className={classes.name}>
          {name}
        </Typography>
      </div>
      {!isDesktop && (
        <>
          <Typography variant="h5" className={classes.text}>
          
            {isExpanded || !largeText ? text : `${text.substring(0, 368)}`}
         
            
          </Typography>
          {largeText && (
            <div className={classes.btnWrapper} >
              <MoreBtn
                handleClick={() => toggleExpanded()}
                text={isExpanded ? t('common-texts.less-btn') : ""}
              />
            </div>
          )}
        </>
      )}

      {isDesktop && (
        <Typography variant="h5" className={classes.text}>
          {text}
        </Typography>
      )}
   </li>
  
  );
};

const useStyles = makeStyles<{ showMore?: boolean; largeText: boolean }>({
  name: { FeedbackItem },
})((theme: Theme, { showMore, largeText }) => ({
  item: {
    position: "relative",
    padding: "15px 15px 25px",
    borderRadius: "20px",
    border: "1px solid #000",
    backgroundColor: "#FAFAFA",
    height: !showMore ? "430px" : "",

    [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
      "&:not(:last-child)": {
        marginRight: "10px",
      },
      "&::after": {
        content: "''",
        display: showMore || !largeText ? "none" : "",
        position: "absolute",
        bottom: "20px",
        left: "0",
        width: "100%",
        height: "130px",
        background:
          "linear-gradient(0deg, #FAFAFA 30.63%, rgba(250, 250, 250, 0.00) 63.74%)",
      },
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      padding: "40px 40px 0px",
      height: "375px",
    },
  },
  nameWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "25px",
    userSelect: "none",

    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      alignItems: "flex-end",
    },
  },
  peopleIcon: {
    width: "32px",
    height: "32px",

    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      width: "60px",
      height: "60px",
    },
  },
  name: {
    fontWeight: "700",
    marginLeft: "15px",
    color: "#0D0D0C",
  },
  text: {
    userSelect: "none",
    [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
      width: "286px",
    },
    color: "#0D0D0C",
  },
  btnWrapper: {
    position: "relative",
    zIndex: "10",
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
  },
}));

export default FeedbackItem;
