import { FC, useState } from "react";
import FieldInput from "../../shared/components/FieldInput/FieldInput";
import CommonButton from "../../shared/components/CommonButton/CommonButton";
import { makeStyles } from "tss-react/mui";
import { Theme, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Formik, Form, Field } from "formik";
import { initialValues } from "../../helpers/initialValues/contactEmail";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import arrowDown from "../../assets/icons/arrow/arrow-down.svg";
import * as Yup from "yup";
import "yup-phone";
import { parsePhoneNumberFromString } from "libphonenumber-js";

interface EmailContactFormProps {
  formSubmit: any;
  isCheckboxTrue: boolean | null;
  setIsCheckboxTrue: any;
  isModal?: boolean;
}

const EmailContactForm: FC<EmailContactFormProps> = ({
  formSubmit,
  isCheckboxTrue,
  setIsCheckboxTrue,
  isModal,
}) => {
  const [isOptionOpen, setOptionOpen] = useState(false);
  const { classes } = useStyles({ isOptionOpen });
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    from_name: Yup.string().required(t("components.form.name-required")),
    number: Yup.string()
    .required(t("components.form.phone-required"))
    .test('is-valid-phone', t("components.form.number-is-not-valid"), (value) => {
      if (!value) return false;
      const phoneNumber = parsePhoneNumberFromString(value);
      return phoneNumber?.isValid() || false;
    }),
    course: Yup.string().required(t("components.form.course-required")),
  });

  const fieldsData = [
    {
      id: 1,
      type: "text",
      name: "from_name",
      placeholder: t("components.form.input-name"),
    },
  ];

  const coursesOption = [
    {
      id: 1,
      name: t("common-texts.first-course"),
      value: "for children 6-12 years old",
      active: true,
    },
    {
      id: 2,
      name: t("common-texts.second-course"),
      value: "for teenagers aged 13-17",
      active: true,
    },
    {
      id: 3,
      name: t("common-texts.third-course"),
      value: "for adults",
      active: true,
    },
  ];

  return (
    <div className={classes.wrapper}>
      <Typography
        variant="h1"
        className={`${classes.title} ${isModal ? classes.modalTitle : ""}`}
      >
        {isModal
          ? t("components.form.modal-title")
          : t("components.form.enroll-title")}
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={formSubmit}
        className={classes.form}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, setFieldValue, errors, touched }) => (
          <Form className={classes.form}>
            {fieldsData.map(({ id, type, name, placeholder }) => (
              <div key={id} className={classes.inputWrapper}>
                <FieldInput
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  required
                  onChange={(e: any) => setFieldValue(name, e.target.value)}
                />
                {(errors[name as keyof typeof errors] &&
                  touched[name as keyof typeof touched]) && (
                  <Typography color="error" variant={"h6"} style={{ marginTop: '10px' }}>
                    {errors[name as keyof typeof errors]}
                  </Typography>
                )}
              </div>
            ))}
            <div className={classes.inputWrapper}>
              <PhoneInput
                country={'ua'}
                value={''}
                onChange={phone => setFieldValue('number', `+${phone}`)}
                inputClass={classes.phoneInput}
              />
              {errors.number && touched.number && (
                <Typography color="error" variant={"h6"} style={{ marginTop: '10px' }}>
                  {errors.number}
                </Typography>
              )}
            </div>
            {!isModal && (
              <div className={classes.optionWrapper}>
                <ReactSVG src={arrowDown} className={classes.arrowDown} />
                <Field
                  as="select"
                  name="course"
                  required
                  className={classes.optionList}
                  onClick={() => setOptionOpen((prev) => !prev)}
                >
                  <option value="" disabled>
                    {t("common-texts.option-course")}
                  </option>
                  {coursesOption
                    .filter(({ active }) => active)
                    .map(({ name, id, value }) => (
                      <option
                        value={value}
                        key={id}
                        onClick={() => setOptionOpen(false)}
                      >
                        {name}
                      </option>
                    ))}
                </Field>
              </div>
            )}

            <div className={classes.checkboxWrapper}>
              <Checkbox
                className={classes.checkbox}
                onChange={(e) => setIsCheckboxTrue(e.target.checked)}
              />
              <Typography variant={"h6"} className={classes.checkboxTitle}>
                {t("components.form.checkbox-title")}
              </Typography>
            </div>
            {isCheckboxTrue === false && (
              <Typography variant={"h6"} className={classes.checkboxError}>
                {t("components.form.error-checkbox-title")}
              </Typography>
            )}

            <CommonButton
              text={t("common-texts.send-text")}
              bgColor={"#FFC857"}
              className={classes.btn}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

const useStyles = makeStyles<{ isOptionOpen: boolean }>({
  name: { EmailContactForm },
})((theme: Theme, { isOptionOpen }) => ({
  wrapper: {
    padding: "40px 10px",
  },
  title: {
    fontSize: "24px",
    fontWeight: 900,
    textAlign: "center",
    color: "#0D0D0C",
    marginBottom: "20px",

    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: "25px",
    },
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      fontSize: "48px",
    },
  },
  modalTitle: {
    fontSize: "24px",
    fontWeight: 400,
    textAlign: "center",
    color: "#0D0D0C",
    marginBottom: "20px",

    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: "35px",
      fontSize: "30px",
    },
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      fontSize: "45px",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },

  optionWrapper: {
    position: "relative",
    width: "100%",
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      maxWidth: "335px",
    },
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      maxWidth: "400px",
    },
  },
  arrowDown: {
    position: "absolute",
    right: "15px",
    top: "15px",
  },
  optionList: {
    width: "100%",
    borderRadius: "10px",
    background: "#FAFAFA",
    border: "1px solid #0D0D0C",
    fontSize: "16px",
    padding: "15px 0px 15px 15px",
    marginBottom: "15px",
    color: "#706f6f",
    cursor: "pointer",
  },
  checkboxWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "5px",
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      maxWidth: "335px",
      marginBottom: "10px",
    },
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      maxWidth: "400px",
    },
  },
  checkbox: {
    padding: "0",
    color: "#000",
    "& .MuiSvgIcon-root": {
      width: "1em",
    },
  },
  checkboxTitle: {
    fontSize: "13px",
    marginLeft: "10px",

    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      fontSize: "16px",
    },
  },
  checkboxError: {
    fontSize: "10px",
    color: "#FF0000",

    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      fontSize: "12px",
    },
  },
  btn: {
    marginTop: "15px",
    cursor: "pointer",

    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginTop: "20px",
    },
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      marginTop: "25px",
    },
  },
  phoneInput: {
    width: '100% !important',
    borderRadius: '10px !important',
    border: "1px solid #B4B4B4 !important",
    background: '#FFF !important',
    color: '#000 !important',
    fontSize: '15px !important',
    paddingTop: '15px !important',
    paddingBottom: '15px !important',
    height: '100% !important',
     
      "&::placeholder": {
        
         color: '#B4B4B4',
      },
      
      [`@media (min-width: ${theme.breakpoints.values.lg -1}px)`]:{
         fontSize: '16px',
         
      }
  },
  inputWrapper: {
    width: '100%',
    "&:not(:last-child)": {
      marginBottom: '25px'
   },
    [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]: {
      maxWidth: '335px',
  },
  [`@media (min-width: ${theme.breakpoints.values.lg -1}px)`]:{
      fontSize: '16px',
      maxWidth: '400px',
 }
  }
}));

export default EmailContactForm;
