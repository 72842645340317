import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import { CommonButtonProps } from "../../../interface/CommonButtonProps";


const CommonButton:FC<CommonButtonProps> = ({text, color, className, bgColor, handleClick, boxShadow}) => {
   const { classes } = useStyles({boxShadow, bgColor});
   
   return (
      
      <button className={`${className} ${classes.button}`} onClick={() => {
            if(!handleClick) return;
            handleClick()
         }}>
         <Typography variant="h4" className={classes.text}>{text}</Typography>
      </button>
   );
};

const useStyles = makeStyles<{boxShadow?: string, bgColor?: string}>({ "name": { CommonButton } })((theme: Theme, {boxShadow, bgColor}) => ({
   button: {
      padding: '16px 32px',
      borderRadius: '5px',
      transition: 'all 0.3s ease-in-out',
      border: '1px solid #0D0D0C',
      backgroundColor: bgColor ? bgColor : theme.palette.secondary.main,
      boxShadow: `5px 5px 0px ${boxShadow ? boxShadow : theme.palette.primary.main}`,
      "&:hover": {
         boxShadow: 'none',
      }
   },
   text: {
      
   }
}));

export default CommonButton;
