import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, useColorScheme, Typography } from "@mui/material";
import { themeIcons } from "../../helpers/themeIcons";
import { ReactSVG } from "react-svg";

interface ThemeSwitherProps {}

const ThemeSwither: FC<ThemeSwitherProps> = ({}) => {
  const { mode, setMode } = useColorScheme();
  const { classes } = useStyles({mode: mode});
  const toggleMode = () => setMode(mode === "dark" ? "light" : "dark");

  return (
    <div className={classes.themeSwitcher} onClick={toggleMode}>
      {themeIcons.map(
        ({ alt, icon }) =>
          mode === alt && (
            <>
               {mode === 'light' && <Typography variant={'h6'} sx={{color: '#1B1B1B', marginRight: '11px'}} className={classes.modeTitle}>DAYMODE</Typography>}
               <div className={`${mode !== alt ? classes.currentMode : ""} ${classes.themeIconWrapper}`}>
                  <ReactSVG
                     key={alt}
                     src={icon}
                     className={`${classes.themeIcon}`}
                  />
               </div>
               {mode === 'dark' && <Typography variant={'h6'} sx={{color: '#FFF', marginLeft: '3px'}} className={classes.modeTitle}>NIGHTMODE</Typography>}
            </>
            
          )
      )}
    </div>
  );
};

const useStyles = makeStyles<{mode: string | undefined}>({ name: { ThemeSwither } })((theme: Theme, {mode}) => ({
  themeSwitcher: {
    display: "flex",
    alignItems: "center",
    borderRadius: "46px",
    maxWidth: "100px",
    width: "100%",
    backgroundColor: mode === "light" ? "#FFF" : '#2E2E2E',
    padding: mode === "light" ? "3px 3px 3px 10px" : '3px 8px 3px 3px',
    cursor: "pointer",
  },
  currentMode: {},
  themeIconWrapper: {
    backgroundColor: mode === "light" ? "#1B1B1B" : '#FFF',
    borderRadius: "50%",
    padding: "5px",
  },
  themeIcon: {
    width: "24px",
    height: "24px",

   
  },
  modeTitle: {
   fontSize: '9px',
   fontWeight: '500',
   userSelect: 'none',
  }
}));

export default ThemeSwither;
