import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme } from "@mui/material";
import { ReactSVG } from 'react-svg'
import { socialList } from "../../helpers/socialList/socialList";

interface SocialListProps {
   listClassName?: string,
   iconsFill?: string,
}

const SocialList:FC<SocialListProps> = ({listClassName, iconsFill}) => {
   const { classes } = useStyles({iconsFill});

   return (
      <ul className={`${classes.list} ${listClassName ? listClassName : ''}`}>
         {socialList.map(({id, link, icon}) => (
            <li key={id} className={classes.item}>
               <a href={link} className={classes.link} target="_blank" rel="noreferrer">
                  <ReactSVG src={icon} className={classes.icon}/>
               </a>
            </li>
         ))}
      </ul>
   );
};

const useStyles = makeStyles<{iconsFill?: string}>({ "name": { SocialList } })((theme: Theme, {iconsFill}) => ({
   list: {
      display: "flex",
      gap: '25px'
   },
   item: {

   },
   link: {
      transition: 'all 0.3s ease-in-out',
      "&:hover": {
         opacity: '0.7'
      }
   },
   icon: {
      fill: iconsFill ? iconsFill : '#FFFFFF',
   }
}));

export default SocialList;
