import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme } from "@mui/material";
import NavigationTab from "../../components/NavigationTab/NavigationTab";
import { useBlogArticles } from "../../helpers/blogArcticles";
import { useParams } from "react-router-dom";
import DetailsPost from "./DetailsPost/DetailsPost";
import ContactUsBlock from "../../components/ContactUsBlock/ContactUsBlock";

interface BlogPostProps {}

const BlogPost: FC<BlogPostProps> = ({}) => {
  const { classes } = useStyles();
  const { articlesDetails } = useBlogArticles();
  const { postId } = useParams();

  return (
    <main className={`${classes.wrapper} main-container`}>
      {articlesDetails
        .filter(({ id }) => postId === id.toString())
        .map(
          ({
            title,
            id,
            image,
            text,
            paragraphTitle,
            descriptions,
            conclusion,
          }) => (
            <>
              <NavigationTab
                prevPage={"/blog"}
                currentPage={`${title.substring(0, 27)}...`}
              />

              <DetailsPost
                image={image}
                title={title}
                text={text}
                paragraphTitle={paragraphTitle}
                descriptions={descriptions}
                conclusion={conclusion}
              />
            </>
          )
        )}
      <ContactUsBlock />
    </main>
  );
};

const useStyles = makeStyles({ name: { BlogPost } })((theme: Theme) => ({
  wrapper: {
    marginBottom: "70px",
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: "100px",
    },
  },
}));

export default BlogPost;
