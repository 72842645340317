import { makeStyles } from "tss-react/mui";
import { FC, useState } from "react";
import { Theme, Typography } from "@mui/material";
import UseMediaQuery from "../../../hooks/UseMediaQuery";
import SectionTitle from "../../../shared/components/SectionTitle/SectionTitle";
import MoreBtn from "../../../shared/components/MoreBtn/MoreBtn";
import arrowIcon from "../../../assets/icons/arrow/arrow-down.svg";
import { ReactSVG } from "react-svg";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
interface FAQSectionProps {}

const FAQSection: FC<FAQSectionProps> = ({}) => {
  const { classes } = useStyles();
  const { isMobile } = UseMediaQuery();
  const [more, setMore] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const { t } = useTranslation();
  const faqList = [
    {
      id: 1,
      title: t("scenes.home.faq-section.first-item.title"),
      answer: t("scenes.home.faq-section.first-item.answer"),
    },
    {
      id: 2,
      title: t("scenes.home.faq-section.second-item.title"),
      answer: t("scenes.home.faq-section.second-item.answer"),
    },
    {
      id: 3,
      title: t("scenes.home.faq-section.third-item.title"),
      answer: t("scenes.home.faq-section.third-item.answer"),
    },
    {
      id: 4,
      title: t("scenes.home.faq-section.fourth-item.title"),
      answer: t("scenes.home.faq-section.fourth-item.answer"),
    },
    {
      id: 5,
      title: t("scenes.home.faq-section.fifth-item.title"),
      answer: t("scenes.home.faq-section.fifth-item.answer"),
    },
    {
      id: 6,
      title: t("scenes.home.faq-section.sixth-item.title"),
      answer: t("scenes.home.faq-section.sixth-item.answer"),
    },

    {
      id: 8,
      title: t("scenes.home.faq-section.seventh-item.title"),
      answer: t("scenes.home.faq-section.seventh-item.answer"),
    },
    {
      id: 9,
      title: t("scenes.home.faq-section.eight-item.title"),
      answer: t("scenes.home.faq-section.eight-item.answer"),
    },
    {
      id: 7,
      title: t("scenes.home.faq-section.ninth-item.title"),
      answer: t("scenes.home.faq-section.ninth-item.answer"),
    },
    {
      id: 10,
      title: t("scenes.home.faq-section.tenth-item.title"),
      answer: t("scenes.home.faq-section.tenth-item.answer"),
    },
  ];
  const handleClick = (id: number) => {
    if (id === currentId) return setCurrentId(null);

    setCurrentId(id);
  };
  return (
    <section className={classes.wrapper} id="faq-section">
      <SectionTitle
        title={t("scenes.home.faq-section.title")}
        className={classes.sectionTitle}
      />
      <div className={classes.contentWrapper}>
        <ul className={classes.list}>
          {isMobile
            ? faqList.map(({ id, title, answer }) => (
                <li
                  key={id}
                  className={`${!more && id >= 6 ? classes.hidden : ""} ${
                    classes.item
                  }`}
                  onClick={() => handleClick(id)}
                >
                  <div className={classes.titleWrapper}>
                    <Typography className={classes.title}>{title}</Typography>
                    <ReactSVG
                      src={arrowIcon}
                      className={`${
                        currentId === id ? classes.rotateIcon : ""
                      } ${classes.arrowIcon}`}
                    />
                  </div>

                  <AnimatePresence>
                    {currentId === id && (
                      <motion.div
                        className={classes.answerWrapper}
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                        style={{ overflow: "hidden" }}
                      >
                        <Typography className={classes.answerText}>
                          {answer}
                        </Typography>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </li>
              ))
            : faqList.map(({ id, title, answer }) => (
                <li
                  key={id}
                  className={`${currentId !== id ? classes.fixHeight : ""} ${
                    classes.item
                  }`}
                  onClick={() => handleClick(id)}
                >
                  <div className={classes.titleWrapper}>
                    <Typography className={classes.title}>{title}</Typography>
                    <ReactSVG
                      src={arrowIcon}
                      className={`${
                        currentId === id ? classes.rotateIcon : ""
                      } ${classes.arrowIcon}`}
                    />
                  </div>
                  {currentId === id && (
                    <motion.div
                      className={classes.answerWrapper}
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      transition={{ duration: 0.3 }}
                    >
                      <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                        className={classes.answerText}
                      >
                        {answer}
                      </motion.p>
                    </motion.div>
                  )}
                </li>
              ))}
        </ul>
        {isMobile && (
          <MoreBtn
            handleClick={() => setMore((prev) => !prev)}
            text={more ? t('common-texts.less-btn') : t('common-texts.more-btn')}
            className={classes.moreBtn}
          />
        )}
      </div>
    </section>
  );
};

const useStyles = makeStyles({ name: { FAQSection } })((theme: Theme) => ({
  wrapper: {
    marginBottom: "70px",
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: "75px",
    },
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: "160px",
    },
  },
  sectionTitle: {
    maxWidth: "185px",
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      maxWidth: "800px",
    },
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    userSelect: "none",
  },
  hidden: {
    display: "none",
  },
  list: {
    display: "flex",

    gap: "15px",
    width: "100%",

    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      flexDirection: "column",
      marginBottom: "25px",
    },
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      flexWrap: "wrap",
      gap: "20px 15px",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      flexWrap: "wrap",
      gap: "25px 15px",
    },
  },
  item: {
    width: "100%",
    padding: "15px 10px",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.primary.main}`,
    background: "#FAFAFA",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      boxShadow: `5px 5px 0px ${theme.palette.primary.main}`,
    },
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      flexBasis: "calc(100% / 2 - 8px)",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      padding: "20px",
    },
  },
  fixHeight: {
    height: "65px",
  },
  titleWrapper: {
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
  },
  title: {
    color: "#0D0D0C",
    fontWeight: 700,
    fontSize: "16px",
    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      maxWidth: "290px",
    },
  },
  arrowIcon: {
    height: "20px",
  },
  rotateIcon: {
    transformOrigin: "center center",
    transform: "rotate(180deg)",
  },
  answerWrapper: {
    marginTop: "15px",
    paddingLeft: "10px",
  },
  answerText: {
    maxWidth: "515px",
    fontSize: "16px",
    color: "#0D0D0C",
  },
  moreBtn: {},
}));

export default FAQSection;
