import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import { makeStyles } from 'tss-react/mui';
import { FC } from 'react';
import { Theme } from '@mui/material';
import { FeedbacksProps } from '../../../../interface/FeedbacksProps';
import FeedbackItem from '../../../../components/FeedbackItem/FeedbackItem';


const DesktopBlock: FC<FeedbacksProps> = ({ feedbacks }) => {
  const { classes } = useStyles();

  return (
    <Swiper
      navigation={{
        nextEl: '.feedbacks-next',
        prevEl: '.feedbacks-prev',
      }}
      modules={[Navigation]}
      slidesPerView={2} 
      spaceBetween={10} 
      className={classes.list}
    >
      

      {feedbacks.map(({ id, name, text }) => (
        <SwiperSlide key={id}>
          <FeedbackItem id={id} name={name} text={text} />
        </SwiperSlide>
      ))}
      
    </Swiper>
  );
};

const useStyles = makeStyles({ name: { DesktopBlock } })((theme: Theme) => ({
  list: {},
}));

export default DesktopBlock;

