import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

export enum Breakpoints {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

const theme = extendTheme({
  breakpoints: {
    values: {
      [Breakpoints.XS]: 390,
      [Breakpoints.SM]: 768,
      [Breakpoints.MD]: 1279,
      [Breakpoints.LG]: 1440,
      [Breakpoints.XL]: 1500,
    },
  },
  typography: {
    fontFamily: 'MontserratRegular, sans-serif',
    fontSize: 16,
    h1: {
      fontFamily: 'Raleway, sans-serif',
      fontSize: '64px',
      lineHeight: '110%',
    },
    h2: {
      fontFamily: 'Raleway, sans-serif',
      fontSize: '48px',
      lineHeight: '110%',
    },
    h3: {
      fontFamily: 'Manrope, sans-serif',
      fontSize: '24px',
      lineHeight: 'normal',
    },
    h4: {
      fontFamily: 'Manrope, sans-serif',
      fontSize: '18px',
      lineHeight: '150%',
    },
    h5: {
      fontFamily: 'Manrope, sans-serif',
      fontSize: '16px',
      lineHeight: '150%',
    },
    h6: {
      fontFamily: 'Manrope, sans-serif',
      fontSize: '14px',
      lineHeight: '100%',
    }
  },
  colorSchemes: {
    light: {
      // palette for light mode
      palette: {
        text: {
          primary: '#0D0D0C', // dark primary 
          secondary: '#B4B4B4', // disable text
        },
        background: {   
          paper: '#FAFAFA', // lite gray bg
          default: '#FFF', // white main bg
        },
        info: {
          main: '#FFC857', // yellow
          light: '#D90000', // red
          contrastText: '#3F3E3E' // dark gray
        },
        primary: {
          main: "#0D0D0C", // dark titles, text
          contrastText: '#FAFAFA', // white titles, text
          light: '#B4B4B4', // navigation titles
          dark: '#2E2E2E', // dark grey
        },
        secondary: {
          main: "#A09EFF", // purple bg
          dark: '#0D0D0C', // dark bg,
          contrastText: '#FFF', // white titles, text
        },
      },
    },
    dark: {
      // palette for dark mode
      palette: {
        text: {
          primary: '#F0F0F0',
          secondary: '#FFFDFD',
        },
        background: {
          paper: 'rgb(125 124 195)',  
          default: '#1E1E1E',
        },
        info: {
          main: '#FFC857', // yellow
          light: '#D90000', // red
          contrastText: '#3F3E3E' // dark gray
        },
        primary: {
          main: "#FFF",
          dark: 'rgb(178, 178, 178)',
        },
        secondary: {
          main: "#A09EFF",
        },
      },
    },
  },
});

export default theme;
