import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import { ReactSVG } from 'react-svg'
import smillingIcon from '../../../assets/icons/lamp/sweet-lamp.svg'
import closeIcon from '../../../assets/icons/burger-menu/close-icon.svg'
import { motion } from "framer-motion";
import UseMediaQuery from "../../../hooks/UseMediaQuery";
import { useTranslation } from "react-i18next";

interface SuccessAnswerProps {
   setIsSuccessSubmit: any
   isModal?: boolean;
}

const SuccessAnswer:FC<SuccessAnswerProps> = ({setIsSuccessSubmit, isModal}) => {
   const { classes } = useStyles({isModal: isModal});
   const {t} = useTranslation()
   return (
      <motion.div 
         className={classes.wrapper}
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         exit={{ opacity: 0 }}
         transition={{ duration: 0.5 }}
      >
        {!isModal && <ReactSVG src={closeIcon} className={classes.closeIcon} onClick={() => setIsSuccessSubmit('')}/>   }
         <ReactSVG src={smillingIcon} className={classes.icon}/>       
         <Typography variant="h2" className={classes.title}>{t('components.form.success-answer-title')}</Typography>
         <Typography variant="h5" className={classes.afterTitle}>{t('components.form.success-answer-after-title')}</Typography>
        
      </motion.div>
   );
};

const useStyles = makeStyles<{isModal?: boolean}>({ "name": { SuccessAnswer } })((theme: Theme, {isModal}) => ({
   wrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%'
   },
   closeIcon: {
      position: 'absolute',
      right: '10px',
      top: '10px',
      width: '32px',
      height: '32px', 
      stroke: '#000',
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      "&:hover": {
         opacity: '0.7'
      },
   },
   icon: {
      width: '100px',
      fill: isModal ? '#FFC857' :"#A09EFF",
      marginBottom: '20px',
     
   },
   title: {
      fontWeight: 700,
      fontSize: '32px',
      textAlign: 'center',
      color: '#0D0D0C',
      marginBottom: '25px',
   },
   afterTitle: {
      textAlign: 'center',
   }
}));

export default SuccessAnswer;
