import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import NavigationTab from "../../components/NavigationTab/NavigationTab";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
interface PrivacyProps {}

const Privacy:FC<PrivacyProps> = ({}) => {
   const { classes } = useStyles();
   const { t } = useTranslation()
   return (
    <main className={`${classes.wrapper} main-container`}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <NavigationTab currentPage={t('components.nav-list.privacy')}/>

      <div className={classes.textWrapper}>
         <Typography variant={'h5'} className={classes.text}>
            {t('scenes.privacy.text')}

         </Typography>
      </div>
     
    </main>
   );
};

const useStyles = makeStyles({ "name": { Privacy } })((theme: Theme) => ({
   wrapper: {
      
      margin: '40px 0px 100px'
   },
   textWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   text: {
      maxWidth: '800px'
   }
}));

export default Privacy;
