import { makeStyles } from "tss-react/mui";
import {  FC, useState } from "react";
import { Theme } from "@mui/material";
import { FeedbacksProps } from "../../../../interface/FeedbacksProps";
import FeedbackItem from "../../../../components/FeedbackItem/FeedbackItem";


const MobileBlock:FC<FeedbacksProps> = ({feedbacks}) => {
   const { classes } = useStyles();
   const [isExpanded, setIsExpanded] = useState<number | null>(null)
   
   const toggleExpanded = (id: number) => {
      if(id === isExpanded){
        return setIsExpanded(null)
      }
      setIsExpanded(id)
   }
   return (
      <ul className={classes.list}>
         {feedbacks.map(({id, name, text}) => (
            <FeedbackItem 
               id={id} 
               name={name} 
               text={text} 
               isExpanded={isExpanded === id} 
               toggleExpanded={() => toggleExpanded(id)} 
            />
         ))}
         
      </ul>
   );
};

const useStyles = makeStyles({ "name": { MobileBlock } })((theme: Theme) => ({
   list: {
      display: "flex",
      paddingLeft: '10px',
      "&::-webkit-scrollbar":{
         display: 'none',
      },
      [`@media (max-width: ${theme.breakpoints.values.md -1}px)`]:{
         overflowX: 'auto',
      },
   }
}));

export default MobileBlock;
