import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import { CommonButtonProps } from "../../../interface/CommonButtonProps";
import { useTranslation } from "react-i18next";

const MoreBtn: FC<CommonButtonProps> = ({
  text,
  className,
  handleClick,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation()
  return (
    <button
      className={`${className} ${classes.button}`}
      onClick={() => {
        if (!handleClick) return;
        handleClick();
      }}
    >
      <Typography variant="h5" className={classes.text}>
        {text ? text : t('common-texts.more-btn')}
      </Typography>
    </button>
  );
};

const useStyles = makeStyles({ name: { MoreBtn } })((theme: Theme) => ({
  button: {
    borderBottom: "2px solid #A09EFF",
  },
  text: {
      fontWeight: '500',
      color: theme.palette.primary.main
  },
}));

export default MoreBtn;
