import { useMediaQuery } from 'react-responsive';
import { useTheme } from '@mui/material';
const UseMediaQuery = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.values.sm - 1}px)`,
  });
  const isTablet = useMediaQuery({
    query: `(min-width: ${breakpoints.values.sm}px) and (max-width: ${breakpoints.values.md}px)`,
  });
  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.values.md}px)`,
  });
  const isMediumDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.values.lg}px)`,
  });
  const isHighDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.values.xl}px)`,
  });
  

  return { isMobile, isTablet, isDesktop, isHighDesktop, isMediumDesktop };
};

export default UseMediaQuery;
