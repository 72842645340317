import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme } from "@mui/material";
import SectionTitle from "../../../shared/components/SectionTitle/SectionTitle";
import NavigateBtn from "../../../shared/components/NavigateBtn/NavigateBtn";
import illustrationIcon from "../../../assets/icons/common/illustration.svg";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PopularArticalList from "../../../components/PopularArticalList/PopularArticalList";
interface BlogSectionProps {}

const BlogSection: FC<BlogSectionProps> = ({}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  
  return (
    <section className={classes.wrapper} id="blog-section">

      <SectionTitle title={t("scenes.home.blog-section.title")} />
      <ReactSVG src={illustrationIcon} className={classes.illustrationIcon} />
      <PopularArticalList />
      <div className={classes.btnWrapper}>
        <Link
          to="/blog"
          state={{ from: location.pathname }}
          onClick={() => {
            window.scroll(0, 0);
          }}
        >
          <NavigateBtn />
        </Link>
      </div>
    </section>
  );
};

const useStyles = makeStyles({ name: { BlogSection } })((theme: Theme) => ({
  wrapper: {
    position: "relative",
    marginBottom: "70px",
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: "100px",
    },
    [`@media (min-width: ${theme.breakpoints.values.xl -1}px)`]:{
      width: '1440px',
      margin: '0 auto 100px',
   }
  },
  illustrationIcon: {
    position: "absolute",
    right: "0",
    top: "-30px",
    width: "151px",
    height: "104px",
    stroke: theme.palette.primary.main,
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      width: "241px",
      top: "-60px",
    },
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      width: "400px",
      top: "-80px",
      right: "-81px",
    },
  },
  list: {
    position: "relative",
    display: "flex",
    marginBottom: "45px",
    zIndex: 10,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
      overflowX: "auto",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      gap: "25px",
    },
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      flexWrap: "wrap",
    },
  },
  item: {
  
    [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
      "&:not(:last-child)": {
        marginRight: "15px",
      },
    },
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      flexBasis: "calc(100% / 3 - 17px)",
    },
  },
  img: {
    maxWidth: "300px",
    height: "250px",
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      maxWidth: "320px",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      maxWidth: "100%",
      width: "100%",
    },
  },
  title: {
    fontWeight: "700",
    marginBottom: "10px",
  },
  text: {
    maxWidth: "300px",
  },
  btnWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

export default BlogSection;
