import axios from 'axios';
// import { FormikHelpers } from "formik";
import { ContactFormData } from "../../interface/ContactFormData";
// import { initialValues } from '../initialValues/contactEmail';

export const emailContactSubmit = async ( values: ContactFormData) => {
  const formData = new FormData();
  formData.append('service_id', 'service_azsr28f');
  formData.append('template_id', 'template_mqtsl6f');
  formData.append('user_id', 'nVXc03Z8bkQMWokRb');
  formData.append('from_name', values.from_name);
  formData.append('email', values.email); 
  formData.append('number', values.number);
  formData.append('course', values.course);
  
  const data = await axios.post('https://api.emailjs.com/api/v1.0/email/send-form', formData)

  return data.data
  
};