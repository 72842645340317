import { makeStyles } from "tss-react/mui";
import { FC, useState } from "react";
import { Theme } from "@mui/material";
import ThemeLogo from "../ThemeLogo/ThemeLogo";
import LanguagesChanger from "../LanguagesChanger/LanguagesChanger";
import { ReactSVG } from "react-svg";
import burgerIcon from "../../assets/icons/burger-menu/burger-menu.svg";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import ThemeSwither from "../ThemeSwither/ThemeSwither";
import UseMediaQuery from "../../hooks/UseMediaQuery";
import useScrollDirection from "../../hooks/useScrollDirection";

interface HeaderProps {
  isMainPage: boolean
}

const Header: FC<HeaderProps> = ({isMainPage}) => {
  const { classes } = useStyles();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const { isMobile } = UseMediaQuery();
  const scrollDirection = useScrollDirection();
  return (
    <header
      className={`${classes.header} ${
        scrollDirection === "down" ? "hide" : ""
      }`}
    >
      <div className={`${classes.headerWrapper}`}>
        <ThemeLogo isMainPage={isMainPage} />

        <div className={classes.rightPartWrapper}>
          {!isMobile && <ThemeSwither />}
          <LanguagesChanger />
          <ReactSVG
            src={burgerIcon}
            className={classes.burgerIcon}
            onClick={() => setOpenMenu(true)}
          />
        </div>
      </div>

      <BurgerMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />
    </header>
  );
};

const useStyles = makeStyles({ name: { Header } })((theme: Theme) => ({
  header: {
    position: "sticky",
    top: "0px",
    height: "6rem",
    transitionProperty: "all",
    transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    transitionDuration: "500ms",
    width: "100%",
    zIndex: 100,
    marginTop: "15px",
    padding: "0 10px",
    "&.hide": {
      top: "-7rem",
    },
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      padding: "0 20px",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      padding: "0 30px",
    },
  },
  headerWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 10px",
    backgroundColor: "#0D0D0C",
    borderRadius: "10px",
    zIndex: 10,

    alignItems: "center",

    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
      padding: "30px 20px",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      padding: "20px 50px",
    },
  },
  rightPartWrapper: {
    display: "flex",
    gap: "25px",
    alignItems: "center",

    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      gap: "40px",
    },
  },
  burgerIcon: {
    paddingTop: "7px",
    cursor: "pointer",
  },
}));

export default Header;
