import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import { NavList } from "../../helpers/navList/navList";
import SocialList from "../SocialList/SocialList";
import ThemeLogo from "../ThemeLogo/ThemeLogo";
import ScrollBlock from "./ScrollBlock/ScrollBlock";
import LinkBlock from "./LinkBlock/LinkBlock";
import { useLocation } from "react-router-dom";

interface FooterProps {
  isMainPage: boolean
}

const Footer: FC<FooterProps> = ({isMainPage}) => {
  const { classes } = useStyles();
  const navList = NavList();
  const location = useLocation();

  return (
    <footer className={classes.footer}>
      <div className={classes.wrapper}>
        <ThemeLogo className={classes.logo} isMainPage={isMainPage} />
        <div className={classes.infoWrapper}>
          {location.pathname === '/' ? <ScrollBlock navList={navList} /> : <LinkBlock navList={navList} />}
  
          <div className={classes.contactsWrapper}>
            <a href="tel:+380663797580" className={classes.contactInfoWrapper}>
              <Typography variant="h5" className={classes.contactInfo}>
                +380663797580
              </Typography>
            </a>
            <a
              href="mailto:viacheslav.englishloom@gmail.com"
              className={classes.contactInfoWrapper}
            >
              <Typography variant="h5" className={classes.contactInfo}>
               viacheslav.englishloom@gmail.com
              </Typography>
            </a>

            <SocialList iconsFill="#FFC857" />
          </div>
        </div>
      </div>
    </footer>
  );
};

const useStyles = makeStyles({ name: { Footer } })((theme: Theme) => ({
  footer: {
    padding: "0px 15px 10px",
  },
  wrapper: {
    display: "flex",

    background: "#0D0D0C",
    padding: "30px 0px 40px",
    borderRadius: "15px",

    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      alignItems: "center",
    },
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      padding: "30px 15px 70px",
    },
    [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
      flexDirection: "column",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      padding: "80px 150px 80px 50px",
    },
  },
  logo: {
    fill: "#FFC857",
    width: "110px",
    marginBottom: "20px",
    [`@media (min-width: ${
      theme.breakpoints.values.sm - 1
    }px) and (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
      margin: "0 auto 30px",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      width: "140px",
      marginRight: "170px",
    },
  },
  infoWrapper: {
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      width: "100%",
    },
  },
  listWrapper: {
    display: "flex",

    alignItems: "center",

    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      flexDirection: "column",
      marginBottom: "30px",
    },
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  list: {
    "&:not(:last-child)": {
      marginBottom: "25px",
    },
  },
  item: {
   
    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      display: 'flex',
      justifyContent: 'center',
    },
    "&:not(:last-child)": {
      marginBottom: "25px",
    },
  },
  name: {
    color: "#FAFAFA",
    transition: "all 0.3s linear",
    cursor: "pointer",
    "&:hover": {
      opacity: "0.6",
    },
    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      textAlign: "center",
    },
  },

  contactsWrapper: {
    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  contactInfoWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "25px",
    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {},
  },
  contactInfo: {
    fontWeight: 700,
    color: "#FAFAFA",
  },
}));



export default Footer;
