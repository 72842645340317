import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import ImageBlock from "./ImageBlock/ImageBlock";
import { ReactSVG } from "react-svg";
import textVector from '../../../assets/icons/common/texts-vector.svg'
import { useTranslation } from "react-i18next";
import CommonButton from "../../../shared/components/CommonButton/CommonButton";
import AnchorLink from "react-anchor-link-smooth-scroll";
interface MainSectionProps {}

const MainSection:FC<MainSectionProps> = ({}) => {
   const { i18n, t } = useTranslation();
   const currentLanguage = i18n.language;
   const { classes } = useStyles({currentLanguage: currentLanguage === 'en'});
   return (
      <section className={classes.wrapper} id="main-section">
         <div className={classes.textWrapper}>
            <div className={classes.titleWrapper}>
               <Typography variant='h1' sx={{display: 'none'}}>{t('scenes.home.main-section.hidden-title')}</Typography>
               <Typography variant='h2' className={classes.title}>{t('scenes.home.main-section.title')}</Typography>
               <ReactSVG src={textVector} className={classes.titleIcon}/>
            </div>
            <Typography variant='h5' className={classes.text} >
               {t('scenes.home.main-section.text')}
            </Typography>
            <AnchorLink offset='100' href={'#form-section'}>
               <CommonButton text={t('common-texts.free-lesson')} className={classes.lessonBtn}/>
            </AnchorLink>
         </div>

         <ImageBlock />
        
        
      </section>
   );
};

const useStyles = makeStyles<{currentLanguage: boolean}>({ "name": { MainSection } })((theme: Theme, {currentLanguage}) => ({
   wrapper: {
      position: 'relative',

      display: "flex",   
      alignItems: "center",
      marginBottom: '70px',
      [`@media (max-width: ${theme.breakpoints.values.sm -1}px)`]:{
         flexDirection: "column-reverse",
         marginBottom: '100px',
      },
      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]:{
        justifyContent: "space-between",
        marginBottom: '180px',
        alignItems: "flex-end",
      },
      
   },
   textWrapper: {
      maxWidth: '310px',
      width: '100%',

      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]: {
         maxWidth: '625px',
      }
   },
   titleWrapper: {
      position: 'relative',
      margin: '60px 0px 40px',
   },
   title: {
      position: 'relative',
      fontSize: '32px',
      color: theme.palette.primary.main,
      fontWeight: 'bold', 
      zIndex: '10',

     
      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]:{
         fontSize: '64px',
      }
   },
   titleIcon: {
      position: 'absolute',
      bottom: '0px',
      right: currentLanguage ? '' : '28px',
      width: "274px",
      height: "26px",

      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]: {
         right: currentLanguage ? '' : '27px',
         bottom: '-5px',
      },

      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]: {
         width: "560px",
         right: currentLanguage ? '' : '60px',
         bottom: '10px',
      }
   },
   text: {
      color: theme.palette.primary.main,
      marginBottom: '40px',

      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]: {
         maxWidth: "540px",
      }
   },
   lessonBtn: {
      margin: '0 auto'
   }
}));

export default MainSection;
