import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";
import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./scenes/Home/Home";

import "./lang/i18n";
import Footer from "./components/Footer/Footer";
import Blog from "./scenes/Blog/Blog";
import BlogPost from "./scenes/BlogPost/BlogPost";
import Contacts from "./scenes/Contacts/Contacts";
import Privacy from "./scenes/Privacy/Privacy";
import LessonForm from "./scenes/LessonForm/LessonForm";
import Modal from "./components/Modal/Modal";

const App = () => {
  const { classes } = useStyles();
  const [mounted, setMounted] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const location = useLocation();
  const isMainPage = location.pathname === "/";

  useEffect(() => {
    setMounted(true);

    if (!localStorage.getItem("modalShown")) {
      const timer = setTimeout(() => {
        setIsOpenModal(true);
        localStorage.setItem("modalShown", "true");
      }, 35000);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.removeItem("modalShown");
    };

    window.addEventListener("beforeunload", clearLocalStorage);

    return () => {
      window.removeEventListener("beforeunload", clearLocalStorage);
    };
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <div className={classes.appWrapper}>
      <Header isMainPage={isMainPage} />
      {/* <Modal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        isLeftPart
      /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:postId" element={<BlogPost />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/lesson-form" element={<LessonForm />} />
      </Routes>
      <Footer isMainPage={isMainPage} />
    </div>
  );
};
const useStyles = makeStyles({ name: { App } })((theme: Theme) => ({
  appWrapper: {},
}));
export default App;
