import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import SlideArrows from "../../../shared/components/SlideArrows/SlideArrows";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useBlogArticles } from "../../../helpers/blogArcticles";
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet-async';

interface DetailsPostProps {
   image: string,
   title: string,
   text: string,
   paragraphTitle: string,
   descriptions: {title: string, text: string}[],
   conclusion: string,
}

const DetailsPost:FC<DetailsPostProps> = ({image, title, text, paragraphTitle, descriptions, conclusion}) => {
   const { classes } = useStyles();
   const { postId } = useParams();
   const navigate = useNavigate();
   const { articles } = useBlogArticles();
   const id = Number(postId);

   const prevHandleClick = () => {    
      if(id === 1) return
      navigate(`/blog/${id - 1}`)
      window.scroll(0, 0);
   }
   const prevNextClick = () => {    
      if(id === articles.allArticles.length) return

      navigate(`/blog/${id + 1}`)
      window.scroll(0, 0);
   }
   return (
      <motion.div 
         className={classes.detailsWrapper}
         initial={{ opacity: 0}}
         animate={{ opacity: 1 }}
         exit={{ opacity: 0}}
         transition={{ duration: 0.5 }}
      
      >
         <Helmet>
            <title>{title}</title>
            <meta name="description" content={text} />
         </Helmet>
         <img src={image} alt="articles img"  className={classes.image}/>
         <h1 style={{display: 'none'}}>{title}</h1>
         <Typography variant="h2" className={classes.title}>{title}</Typography>
         <Typography variant="h5" className={classes.text}>{text}</Typography>

         <div className={classes.textWrapper}>
            <Typography variant="h5" className={classes.text}>{paragraphTitle}</Typography>
            <ul>
               {descriptions?.map(({title, text}, index) => (
                  <li key={index}>
                     <Typography variant="h5" className={classes.descriptionTitle}>{title}</Typography>
                     <Typography variant="h5" className={classes.text}>{text}</Typography>
                  </li>
               ))}
            </ul>
            <Typography variant="h5" className={classes.text}>{conclusion}</Typography>
            
         </div>

         <SlideArrows prevClassName={id === 1 ? classes.disabledBtn : ''} nextClassName={articles.allArticles.length === id ? classes.disabledBtn : ''} handlePrevClick={() => prevHandleClick()} handleNextClick={() => prevNextClick()} showNavigateBtn wrapperClassName={classes.btnWrapper} navigatePathName="/blog"/>
      </motion.div>
   );
};

const useStyles = makeStyles({ "name": { DetailsPost } })((theme: Theme) => ({
   detailsWrapper: {
      marginBottom: '70px',
      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]:{
         marginBottom: '100px',
      },
      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]:{
        marginBottom: '160px',
      }
   },
   image: {
      display: 'block',
      width: '65%',
      margin: '0 auto 65px',
      height: '180px',
      borderRadius: '15px',

      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]: {
        
         width: '80%',
         height: '350px',
      }


   },
   textWrapper: {
      marginBottom: '45px'
   },
   title: {
      fontSize: '24px',
      marginBottom: '25px',
      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]: {
         fontSize: '31px',
      },
      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]: {
         fontSize: '48px',
         maxWidth: '900px'
      }
   },
   text: {
      marginBottom: '25px',
      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]: {
         maxWidth: '730px'
      }
   },
   descriptionTitle: {
      fontWeight: 900,
      marginBottom: '10px'
   },
   btnWrapper: {
      display: 'flex',
      justifyContent: 'space-between'
   },
   disabledBtn: {
      cursor: 'default',
      opacity: '0.5'
   }
}));

export default DetailsPost;
