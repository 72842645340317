import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme } from "@mui/material";
import { ReactSVG } from "react-svg";
import mainImage from "../../../../assets/images/home/main-block/main-image.webp";
import mainAnimation from '../../../../assets/animations/main.json'
import Lottie from "lottie-react";

interface ImageBlockProps {}

const ImageBlock: FC<ImageBlockProps> = () => {
  const { classes } = useStyles();
  
  return (
    <div className={classes.imageWrapper}>
      <Lottie animationData={mainAnimation} loop={true} />
    </div>
  );
};

const useStyles = makeStyles({ name: { ImageBlock } })((theme: Theme) => ({
  imageWrapper: {
    position: "relative",
    width: "212px",

    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      width: "340px",
    },
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      width: "400px",
    },
  },
  mainImage: {
    position: "relative",
    width: "100%",
    zIndex: "10",
    borderRadius: "15%",
    display: 'block'
  },
  unionIcon: {
    position: "absolute",
    top: "-10px",
    left: "-11px",
    zIndex: "1",
    width: "233px",
    height: "167px",
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      width: "375px",
      top: "-14px",
      left: "-14px",
    },

    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      width: "520px",
      top: "-17px",
      left: "-18px",
    },
  },
  starIcon: {
    position: "absolute",
    fill: theme.palette.primary.main,
    zIndex: "11",
  },
  firstStar: {
    width: "55px",
    height: "55px",
    top: "-27px",
    right: "10px",

    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      width: "80px",
    },
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      width: "130px",
      top: "-60px",
    },
  },
  secondStar: {
    width: "25px",
    height: "25px",
    top: "0px",
    right: "-8px",

    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      width: "40px",
      height: "40px",
      top: "10px",
      right: "-20px",
    },

    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      width: "57px",
    },
  },
  thirdStar: {
    width: "15px",
    height: "15px",
    top: "20px",
    right: "25px",

    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      width: "22px",
      height: "22px",
      top: "50px",
      right: "40px",
    },

    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      width: "35px",
      right: "57px",
    },
  },

  rainIcon: {
    position: "absolute",
    width: "73px",
    height: "69px",
    stroke: theme.palette.primary.main,
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      width: "100px",
      height: "91px",
    },
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      width: "155px",
      height: "130px",
    },
  },
  firstRain: {
    bottom: "-50px",
    left: "15px",

    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      bottom: "-80px",
      left: "30px",
    },
  },
  secondRain: {
    bottom: "-35px",
    left: "85px",
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      bottom: "-44px",
      left: "160px",
    },

    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      bottom: "-50px",
      left: "180px",
    },
  },
  thirdRain: {
    bottom: "-15px",
    left: "155px",

    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      bottom: "-22px",
      left: "267px",
    },

    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      bottom: "-15px",
      left: "340px",
    },
  },

  freepik: {
    display: "none",
  },
}));

export default ImageBlock;
