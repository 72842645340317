import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enLang from "./en/en.json";
import uaLang from "./ua/ua.json";
import frLang from "./fr/fr.json";
const currentLanguage = localStorage.getItem("language");

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enLang,
    },
    ua: {
      translation: uaLang,
    },
    fr: {
      translation: frLang,
    },
  },
  lng: currentLanguage?.valueOf() || 'ua',
  fallbackLng: currentLanguage?.valueOf() || "ua",
  interpolation: {
    escapeValue: false,
  },
});
