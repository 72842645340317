import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import SectionTitle from "../../../shared/components/SectionTitle/SectionTitle";
import CommonButton from "../../../shared/components/CommonButton/CommonButton";
import star from "../../../assets/icons/union/star.svg";
import vector from "../../../assets/icons/arrow/ridge-vector.svg";
import rain from "../../../assets/icons/union/rain.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";
interface CoursesSectionProps {}

const CoursesSection: FC<CoursesSectionProps> = ({}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const courses = [
    {
      id: 1,
      preTitle: t("scenes.home.courses-section.first-item.pre-title"),
      title: t("scenes.home.courses-section.first-item.title"),
      during: t("scenes.home.courses-section.first-item.during"),
      list: [
        {
          id: 1,
          title: t("scenes.home.courses-section.first-item.list.firstTitle"),
        },
        {
          id: 2,
          title: t("scenes.home.courses-section.first-item.list.secondTitle"),
        },
        {
          id: 3,
          title: t("scenes.home.courses-section.first-item.list.thirdTitle"),
        },
        {
          id: 4,
          title: t("scenes.home.courses-section.first-item.list.fourthTitle"),
        },
      ],
    },
    {
      id: 2,
      preTitle: t("scenes.home.courses-section.second-item.pre-title"),
      title: t("scenes.home.courses-section.second-item.title"),
      during: t("scenes.home.courses-section.second-item.during"),
      list: [
        {
          id: 1,
          title: t("scenes.home.courses-section.second-item.list.firstTitle"),
        },
        {
          id: 2,
          title: t("scenes.home.courses-section.second-item.list.secondTitle"),
        },
        {
          id: 3,
          title: t("scenes.home.courses-section.second-item.list.thirdTitle"),
        },
        {
          id: 4,
          title: t("scenes.home.courses-section.second-item.list.fourthTitle"),
        },
      ],
    },
    {
      id: 3,
      preTitle: t("scenes.home.courses-section.third-item.pre-title"),
      title: t("scenes.home.courses-section.third-item.title"),
      during: t("scenes.home.courses-section.third-item.during"),
      list: [
        {
          id: 1,
          title: t("scenes.home.courses-section.third-item.list.firstTitle"),
        },
        {
          id: 2,
          title: t("scenes.home.courses-section.third-item.list.secondTitle"),
        },
        {
          id: 3,
          title: t("scenes.home.courses-section.third-item.list.thirdTitle"),
        },
        {
          id: 4,
          title: t("scenes.home.courses-section.third-item.list.fourthTitle"),
        },
      ],
    },
  ];
  return (
    <section className={classes.wrapper} id="courses-section">
      <SectionTitle title={t("scenes.home.courses-section.title")} />
      <ReactSVG src={vector} className={classes.vector} />
      <ReactSVG src={rain} className={classes.rainIcon} />
      <ul className={classes.list}>
        {courses.map(({ id, preTitle, title, during, list }) => (
          <li key={id} className={classes.item}>
            <div className={classes.starWrapper}>
              <ReactSVG src={star} className={classes.star} />
              <Typography variant="h5" className={classes.during}>
                {during}
              </Typography>
            </div>

            <div className={classes.topWrapper}>
              <Typography variant="h4" className={classes.preTitle}>
                {preTitle}
              </Typography>
              <Typography variant="h2" className={classes.title}>
                {title}
              </Typography>
            </div>
            <ul className={classes.detailsList}>
              {list.map(({ id, title }) => (
                <li key={id} className={classes.detailsItem}>
                  <ReactSVG src={star} className={classes.detailsStar} />
                  <Typography variant="h5" className={classes.detailsTitle}>
                    {title}
                  </Typography>
                </li>
              ))}
            </ul>
            <AnchorLink offset="100" href={"#form-section"}>
              <CommonButton
                text={t("common-texts.enroll")}
                className={classes.button}
                boxShadow={"#0D0D0C"}
              />
            </AnchorLink>
          </li>
        ))}

        {/* <li className={`${classes.item} ${classes.openSoonItem}`}>
               <Typography variant='h5' className={classes.openSoonTitle}>{t("scenes.home.courses-section.open-soon.pre-title")}</Typography>
               <Typography variant='h3' className={classes.soonTitle}>{t("scenes.home.courses-section.open-soon.title")}</Typography>              
            </li>  */}
      </ul>
    </section>
  );
};

const useStyles = makeStyles({ name: { CoursesSection } })((theme: Theme) => ({
  wrapper: {
    position: "relative",
    marginBottom: "70px",
    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: "65px",
    },
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: "160px",
    },
  },
  vector: {
    position: "absolute",
    top: "2%",
    right: "2%",
    zIndex: "1",
    width: "80px",
    height: "80px",

    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      top: "2%",
      width: "115px",
      height: "115px",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      top: "0%",
      width: "227px",
      height: "240px",
    },
  },
  rainIcon: {
    position: "absolute",
    right: "20px",
    top: "30px",
    width: "100px",
    height: "119px",
    zIndex: 5,
    stroke: theme.palette.primary.main,
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      width: "232px",
      height: "235px",
      right: "20px",
      top: "0px",
    },
  },
  list: {
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      display: "flex",
    },
    [`@media (min-width: ${
      theme.breakpoints.values.sm - 1
    }px) and (max-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      overflowX: "auto",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      flexWrap: "wrap",
      gap: "25px",
    },
  },

  item: {
    position: "relative",
    padding: "0px 0px 95px",
    backgroundColor: "#FAFAFA",
    border: "1px solid #0D0D0C",
    borderRadius: "17px",
    zIndex: "10",
    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      "&:not(:last-child)": {
        marginBottom: "15px",
      },
    },

    [`@media (min-width: ${
      theme.breakpoints.values.sm - 1
    }px) and (max-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      "&:not(:last-child)": {
        marginRight: "25px",
      },
    },
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      flexBasis: "calc(100% / 3 - 17px)",
    },
  },
  topWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "15px 0px",
    backgroundColor: "#0D0D0C",
    borderRadius: "15px",
    marginBottom: "50px",
  },
  preTitle: {
    color: "#FFF",
    fontWeight: "700px",
    marginBottom: "10px",
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      fontSize: "32px",
    },
  },
  title: {
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "24px",
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      fontSize: "48px",
    },
  },
  starWrapper: {
    position: "absolute",
    top: "15%",
    right: "0",

    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      top: "25%",
    },
  },
  during: {
    position: "absolute",
    top: "35%",
    right: "30px",
    zIndex: 10,
    fontWeight: "700",
  },
  star: {
    width: "105px",
    height: "105px",
  },
  detailsList: {
    marginLeft: "15px",
    marginBottom: "25px",

    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      marginRight: "25px",
    },
  },
  detailsItem: {
    display: "flex",
    "&:not(:last-child)": {
      marginBottom: "10px",
    },
  },
  detailsTitle: {
    maxWidth: "290px",
    color: "#000",
    [`@media (min-width: ${
      theme.breakpoints.values.sm - 1
    }px) and (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
      width: "270px",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      maxWidth: "335px",
    },
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      maxWidth: "345px",
    },
  },
  detailsStar: {
    width: "16px",
    height: "16px",
    marginRight: "10px",
  },
  button: {
    position: "absolute",
    bottom: "30px",
    width: "85%",
    margin: "0 25px",
  },

  openSoonItem: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    border: "1px solid #3D3D3D",
    backgroundColor: "#3D3D3D",
    paddingTop: "15px",
    height: "440px",

    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      height: "485px",
    },
  },
  openSoonTitle: {
    color: "#FFF",
    fontWeight: "700",
    textAlign: "center",

    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      width: "320px",
    },

    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      width: "400px",
    },
  },
  soonTitle: {
    position: "absolute",
    top: "50%",
    width: "100%",
    textAlign: "center",
    transform: "translate(0%, -50%)",
    fontSize: "35px",
    textTransform: "uppercase",
    color: "#FFF",
  },
}));

export default CoursesSection;
