import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme } from "@mui/material";
import Lottie from "lottie-react";
import loader from '../../../assets/animations/loader.json'
interface LoaderProps {}

const Loader:FC<LoaderProps> = ({}) => {
   const { classes } = useStyles();
   return (
      <Lottie animationData={loader} loop={true} className={classes.loader}/>
   );
};

const useStyles = makeStyles({ "name": { Loader } })((theme: Theme) => ({
   loader: {
      margin: '0 auto',
      maxWidth: '200px',
      width: '100%',
      height: '100%'
   }
}));

export default Loader;
