import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import NavigationTab from "../../components/NavigationTab/NavigationTab";
import { useTranslation } from "react-i18next";
import mail from "../../assets/icons/social/mail.svg";
import phone from "../../assets/icons/social/phone.svg";
import { ReactSVG } from "react-svg";
import SectionTitle from "../../shared/components/SectionTitle/SectionTitle";
import UseMediaQuery from "../../hooks/UseMediaQuery";
import ContactUsBlock from "../../components/ContactUsBlock/ContactUsBlock";
import { Helmet } from 'react-helmet-async';

interface ContactsProps {}

const Contacts: FC<ContactsProps> = ({}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { isDesktop } = UseMediaQuery();
  const numbers = [
    { id: 2, tel: "+380663797580" },
    { id: 1, tel: "+380990978037" },
  ];
  return (
    <main className={`${classes.wrapper} main-container`}>
      <Helmet>
        <title>{t("components.nav-list.contacts")}</title>
        <meta name="description" content={"Зв'яжіться з нами і ми відповімо на всі ваші запитання"} />
      </Helmet>
      <NavigationTab
        currentPage={t("components.nav-list.contacts")}
      />

      <section className={classes.sectionWrapper}>
        <SectionTitle title={t("scenes.contacts.title")} />
        <div className={classes.listsWrapper}>
          <ul className={classes.list}>
            <Typography className={classes.listTitle} variant="h5">
              {t("scenes.contacts.phone-title")}
            </Typography>
            {numbers.map(({ id, tel }) => (
              <li key={id} className={classes.item}>
                {!isDesktop ? (
                  <a href={`tel:${tel}`} className={classes.item}>
                    <ReactSVG
                      src={phone}
                      className={`${classes.telIcon} ${classes.icon}`}
                    />

                    <Typography className={classes.text} variant="h5">
                      {tel}
                    </Typography>
                  </a>
                ) : (
                  <>
                    <ReactSVG
                      src={phone}
                      className={`${classes.telIcon} ${classes.icon}`}
                    />

                    <Typography className={classes.text} variant="h5">
                      {tel}
                    </Typography>
                  </>
                )}
              </li>
            ))}
          </ul>
          <ul className={classes.list}>
            <Typography className={classes.listTitle} variant="h5">
              {t("scenes.contacts.mail-title")}
            </Typography>

            <li className={classes.item}>
              <a
                href={`mailto: viacheslav.englishloom@gmail.com`}
                className={classes.item}
              >
                <ReactSVG
                  src={mail}
                  className={`${classes.telIcon} ${classes.icon}`}
                />
                <Typography className={classes.text} variant="h5">
                viacheslav.englishloom@gmail.com
                </Typography>
              </a>
            </li>
          </ul>
          <ul className={classes.list}>
            <Typography className={classes.listTitle} variant="h5">
              {t("scenes.contacts.hours-title")}
            </Typography>

            <li className={classes.item}>
              <Typography
                className={`${classes.text} ${classes.daysTitle}`}
                variant="h5"
              >
                {t("scenes.contacts.days-title")}
              </Typography>
              <Typography className={`${classes.text}`} variant="h5">
                09.00-21.00 {t("scenes.contacts.by-kyiv")}
              </Typography>
            </li>
          </ul>
        </div>
        <ContactUsBlock />
      </section>
    </main>
  );
};

const useStyles = makeStyles({ name: { Contacts } })((theme: Theme) => ({
  wrapper: {
    marginBottom: "70px",
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: "100px",
    },
  },
  sectionWrapper: {},
  listsWrapper: {
    marginBottom: "70px",
    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "100px",
    },
  },
  list: {
    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
      "&:not(:last-child)": {
        marginBottom: "15px",
      },
    },
  },
  listTitle: {
    fontWeight: "bold",
    marginBottom: "15px",
  },
  item: {
    display: "flex",
    "&:not(:last-child)": {
      marginBottom: "10px",
    },
  },
  icon: {
    marginRight: "15px",
  },
  telIcon: {
    fill: theme.palette.primary.main,
  },
  text: {},
  daysTitle: {
    marginRight: "30px",
  },
}));

export default Contacts;
