import CssBaseline from '@mui/material/CssBaseline';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import * as React from 'react';
import theme from './theme';

export default function ThemeRegistry({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
 
      <CssVarsProvider theme={theme} >
        <CssBaseline />
        {children}
      </CssVarsProvider>

  );
}

