import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import firstImage from "../../assets/images/home/blog-block/first-article.webp";
import secondImage from "../../assets/images/home/blog-block/second-article.webp";
import thirdImage from "../../assets/images/home/blog-block/third-article.webp";
import { useLocation, Link } from "react-router-dom";

interface PopularArticalListProps {}

const PopularArticalList: FC<PopularArticalListProps> = ({}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const articles = [
    {
      id: 1,
      mainPageTitle: t("scenes.home.blog-section.first-item.mainPageTitle"),
      title: "",
      mainPageText: t("scenes.home.blog-section.first-item.mainPageText"),
      text: "",
      image: firstImage,
    },
    {
      id: 2,
      mainPageTitle: t("scenes.home.blog-section.second-item.mainPageTitle"),
      title: "",
      mainPageText: t("scenes.home.blog-section.second-item.mainPageText"),
      text: "",
      image: secondImage,
    },
    {
      id: 3,
      mainPageTitle: t("scenes.home.blog-section.third-item.mainPageTitle"),
      title: "",
      mainPageText: t("scenes.home.blog-section.third-item.mainPageText"),
      text: "",
      image: thirdImage,
    },
  ];
  return (
    <ul className={classes.list}>
      {articles.map(({ id, mainPageTitle, mainPageText, image }) => (
        <li className={classes.item} key={id}>
          <Link
            to={`/blog/${id}`}
            onClick={() => {
              window.scroll(0, 0);
            }}
            state={{ from: location.pathname }}
          >
            <img src={image} alt="article's img" className={classes.img} width={400}/>
            <Typography className={classes.title} variant="h5">
              {mainPageTitle}
            </Typography>
            <Typography className={classes.text} variant="h5">
              {mainPageText}
            </Typography>
          </Link>
        </li>
      ))}
    </ul>
  );
};

const useStyles = makeStyles({ name: { PopularArticalList } })(
  (theme: Theme) => ({
    list: {
      position: "relative",
      display: "flex",
      marginBottom: "45px",
      zIndex: 10,
      "&::-webkit-scrollbar": {
        display: "none",
      },
      [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
        overflowX: "auto",
      },
      [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
        gap: "25px",
      },
      [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
        flexWrap: "wrap",
      }, 
    },
    item: {
      borderRadius: "15px",
     
      '&:hover img': {
        boxShadow: `10px 1px 5px ${theme.palette.primary.main}`,
      },
      [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
        "&:not(:last-child)": {
          marginRight: "15px",
        },
      },
      [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
        flexBasis: "calc(100% / 3 - 17px)",
      },
    },
    img: {
      maxWidth: "300px",
      height: "250px",
      borderRadius: "15px",
      transition: "all 0.2s linear",
      [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
        maxWidth: "320px",
      },
      [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
        maxWidth: "100%",
        width: "100%",
      },
    },
    title: {
      fontWeight: "700",
      marginBottom: "10px",
    },
    text: {
      maxWidth: "300px",
      // padding: '0px 0px 10px 10px',
    },
  })
);

export default PopularArticalList;
