import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme } from "@mui/material";
import ContactUsBlock from "../../../components/ContactUsBlock/ContactUsBlock";

interface FormSectionProps {}

const FormSection:FC<FormSectionProps> = ({}) => {
   const { classes } = useStyles();
   return (
      <section className={classes.wrapper} id="form-section">
         <ContactUsBlock />
      </section>
   );
};

const useStyles = makeStyles({ "name": { FormSection } })((theme: Theme) => ({
   wrapper: {
      marginBottom: '70px',
      [`@media (max-width: ${theme.breakpoints.values.sm -1}px)`]:{        
         marginBottom: '75px',
      },
      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]:{
         marginBottom: '160px',
      }
   }
}));

export default FormSection;
