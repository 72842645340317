import { makeStyles } from "tss-react/mui";
import { FC, useEffect } from "react";
import { Theme, Typography } from "@mui/material";
import closeIcon from "../../assets/icons/burger-menu/close-icon.svg";
import { ReactSVG } from "react-svg";
import { NavList } from "../../helpers/navList/navList";
import ThemeSwither from "../ThemeSwither/ThemeSwither";
import CommonButton from "../../shared/components/CommonButton/CommonButton";
import SocialList from "../SocialList/SocialList";
import UseMediaQuery from "../../hooks/UseMediaQuery";
import { useTranslation } from "react-i18next";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

interface BurgerMenuProps {
  openMenu: boolean;
  setOpenMenu: any;
}

const BurgerMenu: FC<BurgerMenuProps> = ({ openMenu, setOpenMenu }) => {
  const { classes } = useStyles({ openMenu: openMenu });
  const { t } = useTranslation();
  const navList = NavList();
  const { isMobile } = UseMediaQuery();
  const location = useLocation();

  const handleNavigate = (link: string) => {
    setTimeout(() => {
      const element = document.getElementById(link);
      console.log(element)
      element?.scrollIntoView();
    }, 0)
    
    setOpenMenu(false)
  }
  
  useEffect(() => {
    openMenu
      ? (
        document.body.style.overflow = "hidden" 
      )
      : (document.body.style.overflowY = "unset");
  }, [openMenu]);

  return (
    <div className={classes.menuWrapper}>
      <ReactSVG
        src={closeIcon}
        className={classes.closeIcon}
        onClick={() => setOpenMenu(false)}
      />

      <ul className={classes.navList}>
        
        {location.pathname === '/' ? navList.map(({ id, link, name, pageLink }) => (
          <>
            {link ? (
              <li key={id} className={classes.navItem}>
                <AnchorLink
                  offset="100"
                  href={`#${link}`}
                  onClick={() => setOpenMenu(false)}
                >
                  <Typography variant="h3" className={classes.navTitle}>
                    {name}
                  </Typography>
                </AnchorLink>
              </li>
            ) : (
              <li key={id} className={classes.navItem}>
                <Link to={pageLink} onClick={() => {
                    setOpenMenu(false)
                    window.scroll(0, 0);
                  }}>
                  <Typography variant="h3" className={classes.navTitle}>
                    {name}
                  </Typography>
                </Link>
              </li>
            )}
          </>
        )) : (
          navList.map(({ id, link, name, pageLink }) => (
            <>
              {link ? (
                <li key={id} className={classes.navItem}>
                  <Link
                    to={`/`}
                    onClick={() => handleNavigate(link)}
                  >
                    <Typography variant="h3" className={classes.navTitle}>
                      {name}
                    </Typography>
                  </Link>
                </li>
              ) : (
                <li key={id} className={classes.navItem}>
                  <Link 
                    to={pageLink} 
                    onClick={() => {
                      setOpenMenu(false)
                      window.scroll(0, 0);
                    }}
                    state={{ from: location.pathname }}
                    
                  >
                    <Typography variant="h3" className={classes.navTitle}>
                      {name}
                    </Typography>
                  </Link>
                </li>
              )}
            </>
          ))
        )}
    
        {isMobile && (
          <li className={classes.navItem}>
            <ThemeSwither />
          </li>
        )}

        <li>
          <Link
            to={'/lesson-form'}
            onClick={() => {
              setOpenMenu(false)
              window.scroll(0, 0)
            }} 
          >
            <CommonButton text={t("common-texts.free-lesson")} />
          </Link>
        </li>
      </ul>
      <SocialList />
    </div>
  );
};

const useStyles = makeStyles<{ openMenu: boolean }>({ name: { BurgerMenu } })(
  (theme: Theme, { openMenu }) => ({
    menuWrapper: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      width: "100%",
      height: "100%",
      overflowY: "auto",
      background: theme.palette.primary.dark,
      zIndex: 1000,
      overflow: "scroll",
      transition: "transform 0.3s linear",
      padding: "60px 10px",
      transform: openMenu ? "translateX(0)" : "translateX(100%)",
      [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "0px 40px",
      },
    },
    closeIcon: {
      position: "absolute",
      width: "32px",
      height: "32px",
      top: "10px",
      right: "10px",
      cursor: "pointer",
      stroke: "white",
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        opacity: "0.7",
      },

      [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
        top: "60px",
        right: "60px",
        width: "50px",
        height: "50px",
      },
    },
    navList: {
      marginBottom: "25px",
    },
    navItem: {
      borderBottom: "1px solid #3F3E3E",
      paddingBottom: "10px",
      marginBottom: "10px",
      cursor: "pointer",

      [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
        marginBottom: "20px",
      },
    },
    navTitle: {
      color: theme.palette.secondary.contrastText,
      width: "fit-content",
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        color: theme.palette.secondary.main,
      },

      [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
        fontSize: "35px",
      },
    },
  })
);

export default BurgerMenu;
