import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface ScrollBlockProps {
  navList: { id: number; link?: string; name: string; pageLink?: string }[];
}

const ScrollBlock: FC<ScrollBlockProps> = ({ navList }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <ul className={classes.list}>
        {navList
          .filter(({ id, name }) => id <= 3 && name !== "FAQ")
          .map(({ id, link, name, pageLink }) =>
            link ? (
              <li key={id} className={classes.item}>
                <AnchorLink offset="100" href={`#${link}`}>
                  <Typography variant="h5" className={classes.name}>
                    {name}
                  </Typography>
                </AnchorLink>
              </li>
            ) : (
              <li key={id} className={classes.item}>
                <Link
                  to={pageLink}
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <Typography variant="h5" className={classes.name}>
                    {name}
                  </Typography>
                </Link>
              </li>
            )
          )}
      </ul>
      <ul className={classes.list}>
        {navList
          .filter(({ id, name }) => id >= 4 && name !== "FAQ")
          .map(({ id, link, name, pageLink }) =>
            link ? (
              <li key={id} className={classes.item}>
                <AnchorLink offset="100" href={`#${link}`}>
                  <Typography variant="h5" className={classes.name}>
                    {name}
                  </Typography>
                </AnchorLink>
              </li>
            ) : (
              <li key={id} className={classes.item}>
                <Link
                  to={pageLink}
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <Typography variant="h5" className={classes.name}>
                    {name}
                  </Typography>
                </Link>
              </li>
            )
          )}
        <li className={classes.item}>
          <Link to={"/privacy-policy"}>
            <Typography
              variant="h5"
              className={classes.name}
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              {t("components.nav-list.privacy")}
            </Typography>
          </Link>
        </li>
      </ul>
    </>
  );
};

const useStyles = makeStyles({ name: { ScrollBlock } })((theme: Theme) => ({
  list: {
    "&:not(:last-child)": {
      marginBottom: "25px",
    },
  },
  item: {
    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      display: "flex",
      justifyContent: "center",
    },
    "&:not(:last-child)": {
      marginBottom: "25px",
    },
  },
  name: {
    color: "#FAFAFA",
    transition: "all 0.3s linear",
    cursor: "pointer",
    "&:hover": {
      opacity: "0.6",
    },
    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      textAlign: "center",
    },
  },
}));

export default ScrollBlock;
