import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
interface NavigateBtnProps {
  className?: string;
  text?: string;
}

const NavigateBtn: FC<NavigateBtnProps> = ({
  className,
  text,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <button
      className={`${classes.btn} ${className ? className : ""}`}
    >
      <Typography variant="h5" className={`${classes.text} `}>
        {text ? text : t("common-texts.all-articles-text")}
      </Typography>
    </button>
  );
};

const useStyles = makeStyles({ name: { NavigateBtn } })((theme: Theme) => ({
  btn: {
    padding: "15px 32px",
    boxShadow: `5px 5px 0px #A09EFF`,
    backgroundColor: "#FAFAFA",
    borderRadius: "5px",
    border: "1px solid #0D0D0C",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      boxShadow: "none",
    },
  },
  text: {
    color: "#0D0D0C",
    fontWeight: "500",
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      fontSize: "18px",
    },
  },
}));

export default NavigateBtn;
