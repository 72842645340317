import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";

interface SectionTitleProps {
   title: string;
   className?: string;
   withoutMargin?: boolean;
}

const SectionTitle:FC<SectionTitleProps> = ({title, className, withoutMargin}) => {
   const { classes } = useStyles({withoutMargin: withoutMargin});
   return <Typography variant='h2' className={`${classes.title} ${className ? className : ''}`}>{title}</Typography>;
};

const useStyles = makeStyles<{withoutMargin?: boolean}>({ "name": { SectionTitle } })((theme: Theme, {withoutMargin}) => ({
   title: {
      maxWidth: '330px',
      fontSize: '24px',
      fontWeight: 'bold', 
      color: theme.palette.primary.main,
      marginBottom: withoutMargin ? '' : '32px',
      userSelect: 'none',
      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]: {
         fontSize: '31px',
         maxWidth: '525px',
         marginBottom: withoutMargin ? '' : '42px',
      },
      [`@media (min-width: ${theme.breakpoints.values.lg -1}px)`]: {
         fontSize: '48px',
         maxWidth: '675px',
         marginBottom: withoutMargin ? '' : '64px',
      }
   }
}));

export default SectionTitle;
