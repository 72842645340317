import { useTranslation } from "react-i18next";
import firstImage from "../assets/images/home/blog-block/first-article.webp";
import secondImage from "../assets/images/home/blog-block/second-article.webp";
import thirdImage from "../assets/images/home/blog-block/third-article.webp";
import howAreYou from "../assets/images/blog/how-are-you.jpg";
import movie from "../assets/images/blog/english-movie.jpg";
import continues from "../assets/images/blog/present-continuous.jpg";
import simple from "../assets/images/blog/present-simple.png";

export const useBlogArticles = () => {
  const { t } = useTranslation();

  const articles = {
    popular: [
      {
        id: 1,
        title: t("scenes.home.blog-section.first-item.mainPageTitle"),
        text: t("scenes.home.blog-section.first-item.mainPageText"),
        image: firstImage,
      },
      {
        id: 2,
        title: t("scenes.home.blog-section.second-item.mainPageTitle"),
        text: t("scenes.home.blog-section.second-item.mainPageText"),
        image: secondImage,
      },
      {
        id: 3,
        title: t("scenes.home.blog-section.third-item.mainPageTitle"),
        text: t("scenes.home.blog-section.third-item.mainPageText"),
        image: thirdImage,
      },
    ],
    allArticles: [
      {
        id: 4,
        title: t("scenes.blog.all-articles.fourth-item.title"),
        text: t("scenes.blog.all-articles.fourth-item.text"),
        image: howAreYou,
      },
      {
        id: 5,
        title: t("scenes.blog.all-articles.fifth-item.title"),
        text: t("scenes.blog.all-articles.fifth-item.text"),
        image: movie,
      },
      {
        id: 6,
        title: t("scenes.blog.all-articles.sixth-item.title"),
        text: t("scenes.blog.all-articles.sixth-item.text"),
        image: continues,
      },

      {
         id: 1,
         title: t("scenes.blog.all-articles.first-item.title"),
         text: t("scenes.blog.all-articles.first-item.text"),
         image: firstImage,
       },
       {
         id: 2,
         title: t("scenes.blog.all-articles.second-item.title"),
         text: t("scenes.blog.all-articles.second-item.text"),
         image: secondImage,
       },
       {
         id: 3,
         title: t("scenes.blog.all-articles.third-item.title"),
         text: t("scenes.blog.all-articles.third-item.text"),
         image: thirdImage,
       },
    ],
  };

  const articlesDetails = [
    {
      id: 1,
      title: t("scenes.blog.all-articles.first-item.title"),
      text: t("scenes.blog.all-articles.first-item.text"),
      image: firstImage,
      paragraphTitle: t(
        "scenes.blog.all-articles.first-item.descriptions.first.title"
      ),
      descriptions: [
        {
          title: t(
            "scenes.blog.all-articles.first-item.descriptions.first.first-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.first-item.descriptions.first.first-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.first-item.descriptions.first.second-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.first-item.descriptions.first.second-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.first-item.descriptions.first.third-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.first-item.descriptions.first.third-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.first-item.descriptions.first.fourth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.first-item.descriptions.first.fourth-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.first-item.descriptions.first.fifth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.first-item.descriptions.first.fifth-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.first-item.descriptions.first.sixth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.first-item.descriptions.first.sixth-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.first-item.descriptions.first.sevent-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.first-item.descriptions.first.sevent-item.text"
          ),
        },
      ],
      conclusion: t("scenes.blog.all-articles.first-item.conclusion"),
    },
    {
      id: 2,
      title: t("scenes.blog.all-articles.second-item.title"),
      text: t("scenes.blog.all-articles.second-item.text"),
      image: secondImage,
      paragraphTitle: t(
        "scenes.blog.all-articles.second-item.descriptions.first.title"
      ),
      descriptions: [
        {
          title: t(
            "scenes.blog.all-articles.second-item.descriptions.first.first-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.second-item.descriptions.first.first-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.second-item.descriptions.first.second-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.second-item.descriptions.first.second-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.second-item.descriptions.first.third-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.second-item.descriptions.first.third-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.second-item.descriptions.first.fourth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.second-item.descriptions.first.fourth-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.second-item.descriptions.first.fifth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.second-item.descriptions.first.fifth-item.text"
          ),
        },
      ],
      conclusion: t("scenes.blog.all-articles.second-item.conclusion"),
    },
    {
      id: 3,
      title: t("scenes.blog.all-articles.third-item.title"),
      text: t("scenes.blog.all-articles.third-item.text"),
      image: thirdImage,
      paragraphTitle: t(
        "scenes.blog.all-articles.third-item.descriptions.first.title"
      ),
      descriptions: [
        {
          title: t(
            "scenes.blog.all-articles.third-item.descriptions.first.first-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.third-item.descriptions.first.first-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.third-item.descriptions.first.second-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.third-item.descriptions.first.second-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.third-item.descriptions.first.third-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.third-item.descriptions.first.third-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.third-item.descriptions.first.fourth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.third-item.descriptions.first.fourth-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.third-item.descriptions.first.fifth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.third-item.descriptions.first.fifth-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.third-item.descriptions.first.sixth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.third-item.descriptions.first.sixth-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.third-item.descriptions.first.seventh-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.third-item.descriptions.first.seventh-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.third-item.descriptions.first.eight-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.third-item.descriptions.first.eight-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.third-item.descriptions.first.nineth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.third-item.descriptions.first.nineth-item.text"
          ),
        },
      ],
      conclusion: t("scenes.blog.all-articles.third-item.conclusion"),
    },
    {
      id: 4,
      title: t("scenes.blog.all-articles.fourth-item.title"),
      text: t("scenes.blog.all-articles.fourth-item.text"),
      image: howAreYou,
      paragraphTitle: t(
        "scenes.blog.all-articles.fourth-item.descriptions.first.title"
      ),
      descriptions: [
        {
          title: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.first-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.first-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.second-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.second-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.third-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.third-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.fourth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.fourth-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.fifth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.fifth-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.sixth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.sixth-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.sevent-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.sevent-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.eight-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.eight-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.nineth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.nineth-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.tenth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.fourth-item.descriptions.first.tenth-item.text"
          ),
        },
      ],
      conclusion: t("scenes.blog.all-articles.fourth-item.conclusion"),
    },
    {
      id: 5,
      title: t("scenes.blog.all-articles.fifth-item.title"),
      text: t("scenes.blog.all-articles.fifth-item.text"),
      image: movie,
      paragraphTitle: t(
        "scenes.blog.all-articles.fifth-item.descriptions.first.title"
      ),
      descriptions: [
        {
          title: t(
            "scenes.blog.all-articles.fifth-item.descriptions.first.first-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.fifth-item.descriptions.first.first-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.fifth-item.descriptions.first.second-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.fifth-item.descriptions.first.second-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.fifth-item.descriptions.first.third-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.fifth-item.descriptions.first.third-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.fifth-item.descriptions.first.fourth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.fifth-item.descriptions.first.fourth-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.fifth-item.descriptions.first.fifth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.fifth-item.descriptions.first.fifth-item.text"
          ),
        },
      ],
      conclusion: t("scenes.blog.all-articles.fifth-item.conclusion"),
    },
    {
      id: 6,
      title: t("scenes.blog.all-articles.sixth-item.title"),
      text: t("scenes.blog.all-articles.sixth-item.text"),
      image: continues,
      paragraphTitle: t(
        "scenes.blog.all-articles.sixth-item.descriptions.first.title"
      ),
      descriptions: [
        {
          title: t(
            "scenes.blog.all-articles.sixth-item.descriptions.first.first-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.sixth-item.descriptions.first.first-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.sixth-item.descriptions.first.second-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.sixth-item.descriptions.first.second-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.sixth-item.descriptions.first.third-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.sixth-item.descriptions.first.third-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.sixth-item.descriptions.first.fourth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.sixth-item.descriptions.first.fourth-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.sixth-item.descriptions.first.fifth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.sixth-item.descriptions.first.fifth-item.text"
          ),
        },
        {
         title: t(
           "scenes.blog.all-articles.sixth-item.descriptions.first.sixth-item.title"
         ),
         text: t(
           "scenes.blog.all-articles.sixth-item.descriptions.first.sixth-item.text"
         ),
       },
      ],
      conclusion: t("scenes.blog.all-articles.sixth-item.conclusion"),
    },
    {
      id: 7,
      title: t("scenes.blog.all-articles.fourth-item.title"),
      text: t("scenes.blog.all-articles.fourth-item.text"),
      image: simple,
      paragraphTitle: t(
        "scenes.blog.all-articles.second-item.descriptions.first.title"
      ),
      descriptions: [
        {
          title: t(
            "scenes.blog.all-articles.second-item.descriptions.first.first-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.second-item.descriptions.first.first-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.second-item.descriptions.first.second-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.second-item.descriptions.first.second-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.second-item.descriptions.first.third-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.second-item.descriptions.first.third-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.second-item.descriptions.first.fourth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.second-item.descriptions.first.fourth-item.text"
          ),
        },
        {
          title: t(
            "scenes.blog.all-articles.second-item.descriptions.first.fifth-item.title"
          ),
          text: t(
            "scenes.blog.all-articles.second-item.descriptions.first.fifth-item.text"
          ),
        },
      ],
      conclusion: t("scenes.blog.all-articles.second-item.conclusion"),
    },
  ];
  return { articles, articlesDetails };
};
