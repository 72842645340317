import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme } from "@mui/material";
import EmailContactForm from "../../../components/EmailContactForm/EmailContactForm";
import SuccessAnswer from "../../../components/ContactUsBlock/SuccessAnswer/SuccessAnswer";
import Loader from "../Loader/Loader";

interface CommonFormProps {
   loading: boolean,
   isError: boolean,
   formSubmit: any,
   isCheckboxTrue: boolean | null,
   setIsCheckboxTrue: any,
   setIsSuccessSubmit: any,
   isSuccessSubmit: string,
   isModal?: boolean,
}

const CommonForm:FC<CommonFormProps> = ({isSuccessSubmit, loading, isError, formSubmit, isCheckboxTrue, setIsCheckboxTrue, setIsSuccessSubmit, isModal}) => {
   const { classes } = useStyles({isSuccessSubmit: isSuccessSubmit === "OK", isModal: isModal});
   return (
      <div className={classes.formWrapper}>
            
         {!loading ? <>
            {isSuccessSubmit !== 'OK' && !isError ? (
               <EmailContactForm isModal={isModal} formSubmit={formSubmit} isCheckboxTrue={isCheckboxTrue} setIsCheckboxTrue={setIsCheckboxTrue}/>
               ) : (
                  <SuccessAnswer isModal={isModal} setIsSuccessSubmit={setIsSuccessSubmit}/>
               )
            }
         </>
         : 
            <Loader />
         }
      </div>
   );
};

const useStyles = makeStyles<{isSuccessSubmit: boolean, isModal?: boolean}>({ "name": { CommonForm } })((theme: Theme, {isSuccessSubmit, isModal}) => ({
   formWrapper: {
      background: !isModal ? isSuccessSubmit ? '#FFC857' : '#A09EFF' : "#A09EFF",
      borderRadius: '15px',
      height: '470px',
      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]: {
         height: '520px',
      },
      [`@media (min-width: ${theme.breakpoints.values.lg -1}px)`]: {
         width: '50%',
         
      }
   },
}));

export default CommonForm;
