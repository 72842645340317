import { useTranslation } from "react-i18next";

 

export const NavList = () => {
   const { t } = useTranslation();

   const navList = [
      {
         id: 1,
         link: "courses-section",
         name: t('components.nav-list.courses'),
      },
      {
         id: 2,
         link: "price-section",
         name: t('components.nav-list.price'), 
      },
      {
         id: 3,
         pageLink: "/blog",
         name: t('components.nav-list.blog'), 
      },
      {
         id: 6,
         link: "faq-section",
         name: "FAQ", 
      },
      {
         id: 4,
         pageLink: "/contacts",
         name: t('components.nav-list.contacts'), 
      },
      // {
      //    id: 5,
      //    pageLink: '',
      //    name: t('components.nav-list.vacancies'), 
      // },
   ]

   return navList
}
